import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
  selector: 'app-contribution',
  templateUrl: './contribution.component.html',
  styleUrls: ['../cart.component.scss','./contribution.component.scss']
})
export class ContributionComponent implements OnInit {
	addressLine1: String = null;
	addressLine2: String = null;
	addressTown: String = null;
	addressPostcode: string = null;
	addressResults: Array<{ "addressLine2": string, "addressTown": string, "addressPostcode": string }> = [];
	addressesShown: boolean = false;
	showAddressFinder:boolean = false;
	searchQuery: String = null;
	isLoggedIn: Boolean = false;
	loggedInUser: Object = null;
	autofill: Boolean = false;
	cartContents: Array<any> = [];
	totalCheckout: number = null;

	userAddressLine1: String = null;
	userAddressLine2: String = null;
	userAddressTown: String = null;
	userAddressPostcode: String = null;

	checkoutFirstname: String = null;
	checkoutLastname: String = null;
	checkoutPhone: String = null;
	checkoutEmail: String = null;
	checkoutEmailConf: String = null;
	checkoutPassword: String = null;
	checkoutPasswordConf: String = null;

	accountPasswordShown: boolean = false;
	confirmPasswordShown: boolean = false;
	showFormError: boolean = false;
	apiError: String = '';

	newDeviceCost: number = null;
	amountDeviceCost: number = null;
	newDeviceFinalCost: number = null;
	newDeviceNumber: number = null;

	remainingAmount: number = null;

	calcError: string = null;


	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));
			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
				this.remainingAmount = this.totalCheckout;
			}
		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		console.log(this.remainingAmount);
		

	}

	logOut() {
		this.isLoggedIn = false;
		this.loggedInUser = null;
		this.addressResults = [];
		this.addressLine1 = null;
		this.addressLine2 = null;
		this.addressTown = null;
		this.addressPostcode = null;
		this.checkoutFirstname = null;
		this.checkoutLastname = null;
		this.checkoutPhone = null;
		this.checkoutEmail = null;
		this.checkoutEmailConf = null;
		this.checkoutPassword = null;
		this.checkoutPasswordConf = null;
		this.autofill = false;
		jQuery(".autofill-address").hide();
		jQuery('.address-finder-form').css('display', 'block');
		localStorage.setItem('user', "");
	}


	toggleAccountPassword () {
		this.accountPasswordShown = !this.accountPasswordShown;
	}

	toggleConfirmPassword () {
		this.confirmPasswordShown = !this.confirmPasswordShown;
	}

	calculateNewDeviceCost() {
		if (this.amountDeviceCost > this.totalCheckout) {
			this.calcError = "This value cannot exceed £";
		} else {
			this.calcError = null;
			this.newDeviceFinalCost = this.newDeviceCost - this.amountDeviceCost;
			this.remainingAmount = this.totalCheckout - this.amountDeviceCost;
		}
	}

	continueCheckout() {
		if (this.amountDeviceCost > 0) {
			localStorage.setItem('new-device-payment', JSON.stringify({ "remaining-amount": this.remainingAmount, "upfront-cost": this.newDeviceFinalCost}))
		}
		
		this.router.navigateByUrl('/cart/review');

		var errors = 0;
		
		// if (errors == 0) {
		// 	jQuery(".cart-continue-button").css('opacity', '0.5');
		// 	jQuery(".cart-continue-button").html("Loading");
		// 	var address = { "AddressID": 1, "Name": "Postage Address", "Address1": this.addressLine1, "Address2": this.addressLine2, "City": this.addressTown, "PostCode": this.addressPostcode };
		// 	var request = 'firstName=' + this.checkoutFirstname + '&surname=' + this.checkoutLastname + '&email=' + this.checkoutEmail + '&mobileTelNumber=' + this.checkoutPhone + '&daytimeTelNumber=' + this.checkoutPhone + '&packAddressString=' + JSON.stringify(address);
		// 	this.ingrammicro.createRequest('POST', '/api/Registration/Register', request).subscribe(response => {
		// 		if (response['IsValid'] != true) {
		// 			jQuery(".field-error").remove();
		// 			jQuery(".cart-continue-bar").after("<div class='field-error'>" + response['ValidationMessage'] + "</div>");
		// 			jQuery(".cart-continue-button").css('opacity', '1');
		// 			jQuery(".cart-continue-button").html("Continue");
		// 		} else {
		// 			var newUser = { 'FirstName': this.checkoutFirstname, 'LastName': this.checkoutLastname, 'FullName': this.checkoutFirstname + ' ' + this.checkoutLastname, 'Email': this.checkoutEmail, 'DaytimeTelNo': this.checkoutPhone, 'CustomerID': response['CustomerID'], 'packAddressString': JSON.stringify(address) }
		// 			localStorage.setItem('user', JSON.stringify(newUser));
		// 			this.router.navigateByUrl('/cart/review');
		// 		}
		// 	});
		// }
	}
}
