import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterComponent } from 'src/app/global/footer/footer.component';
import { FooterService } from 'src/app/global/footer/footer.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

declare interface userInterface{
  Username: String;
  firstName: String;
  lastName: String;
  emailaddress: String;
  UserNo: Number;
  UserID: String;
  SenderName: String;
  Store: any;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
 
  showPopup: boolean = false;
  users: Array<any> = [];
  allUsers: Array<any> = [];
  selectedUser: userInterface;
  loggedInEmail: String = null;
  loggedInUserID: String = null;
  loggedInUserRole: String = null;
  loggedInStoreID: String = null;

  showEditStore: Boolean = false;
  stores: Array<any> = [];
  selectedStore: any = null;
  selectedStoreID: String = null;
  storeSearchString: String = null;
  storesReturned: Array<any> = [];

  userSearchString = null;

  isSearching: boolean = false;
  isDeleting: boolean = false;
  isUserSearching: boolean = false;

  constructor(private headerService: HeaderService, private footerService: FooterService, private ingrammicro: IngrammicroService, private http: HttpClient, private router: Router) {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);
  }

  ngOnInit() { 
    if (localStorage.getItem("staff-user")) {
      var user = JSON.parse(localStorage.getItem('staff-user'));
      this.loggedInStoreID = user['StoreSenderNo'];
      this.loggedInUserRole = 'store-user';
      this.loggedInEmail = user['Email'];
      this.loggedInUserID = user['UserID'];

      if(user['RoleID'] == '8921af30-fa81-4ffb-8166-94e438567590'){
        this.loggedInUserRole = 'admin';
      } else if (user['RoleID'] == 'cb1350b1-f2e1-4abe-bc1a-8e6e0f0fc983') {
        this.loggedInUserRole = 'store-admin';
      }

      if(this.loggedInUserRole != 'admin' && this.loggedInUserRole != 'store-admin') {
        this.router.navigateByUrl('/dashboard');
      }

      this.getUsers();
    }
  }

  openPopup(user) {
    this.selectedUser = user;
    this.showPopup = true;
    console.log(user);
  }

  closePopup() {
    this.showPopup = false;
  }

  deleteUser(){
    this.isDeleting = true;
    this.ingrammicro.createRequest('GET', '/api/RetailUserManagement/DeleteStoreUser?UserName='+this.selectedUser.Username+'&UserID='+this.loggedInUserID+'&email='+this.loggedInEmail).subscribe(response => {
      if(response){
        window.location.reload();
      }
      else{
        console.log(response);
        this.isDeleting = false;
      }
    });
  }

  openEditStore(){
    this.showEditStore = true;
    this.selectedUser.Store = {};
    this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/GetStoreForUser?UserID='+this.selectedUser.UserID+'&email='+this.selectedUser.emailaddress).subscribe(response => {
        console.log(response);
        this.selectedUser.Store = response;
    }, response => {

    });
  }

  selectStore(storeSelected){
    this.storeSearchString = storeSelected.SenderName;
    this.selectedStoreID = storeSelected.SenderNo;
    this.storesReturned = [];
  }

  updateUserStore(){
    if(this.storeSearchString){
      this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/UpdateStoreUser', 'Username='+this.selectedUser.Username+'&StoreId='+this.selectedStoreID).subscribe(response => {
        if(response == true){
          //As we dont have a message handler the console will have to do for now.
          console.info('SUCCESS');
          window.location.reload();
        } else {

          //As we dont have a message handler the console will have to do for now.
          console.error('Unable to update users store');
          console.error(response);
        }
      }, response => {
        //As we dont have a message handler the console will have to do for now.
        console.error(response);
      });
    } else {
      console.error('Please search for a store.')
    }
    
  }

  searchForStores() {
    this.isSearching = true;
    this.storesReturned = [];
    this.ingrammicro.createRequest('GET', '/api/RetailUserManagement/GetStoresSearch?email='+this.loggedInEmail+'&UserID='+this.loggedInUserID+'&SearchText='+this.storeSearchString).subscribe(response => {
        this.storesReturned = response;
        if(this.storesReturned.length < 1) {
            this.storesReturned[0] = {SenderName: 'No Stores Found'};
        }
    }, response => {
      console.error(response);
    });
    this.isSearching = false;
  }

  onSearchChange(searchValue: string) {
      this.searchForStores();
  }

  searchForUsers() {
    this.isUserSearching = true;
    this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/GetUsersinStoreSearch?email='+this.loggedInEmail+'&UserID='+this.loggedInUserID+'&SearchUser='+this.userSearchString).subscribe(response => {
      this.isUserSearching = false;
      console.log(response);
      this.users = response;
    }, response => {
      console.error(response);
    });
  }

  onSearchUserChange(searchValue: string) {
    if (this.userSearchString) {
      this.searchForUsers();
    } else {
      this.getUsers();
    }
  }

  getUsers() {
    if (this.loggedInUserRole == 'admin') {
      this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/GetAllUsersinStore?email='+this.loggedInEmail+'&UserID='+this.loggedInUserID).subscribe(response => {
        this.users = response;
      });
    } else if (this.loggedInUserRole == 'store-admin') {
      this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/GetUsersinStore?storeID='+this.loggedInStoreID +'&email='+this.loggedInEmail+'&UserID='+this.loggedInUserID).subscribe(response => {
        this.users = response;
      });
    }
  }
}
