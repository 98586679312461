import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../global/header/header.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-howitworks',
  templateUrl: './howitworks.component.html',
  styleUrls: ['./howitworks.component.scss']
})
export class HowitworksComponent implements OnInit {

  constructor(private headerService: HeaderService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('How it works | Three');
		//this.meta.addTag({name: 'keywords', content: 'Angular Project, Create Angular Project'});

    this.headerService.setShowHeader(true);
  }

}
