import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';

@Component({
	selector: 'app-complete',
	templateUrl: './complete.component.html',
	styleUrls: ['../cart.component.scss', './complete.component.scss']
})
export class CompleteComponent implements OnInit {

	postageType: Number = 0;
	reference: Number = 0;
	fullName: String = null;
	IMEI: String = null;
	phoneMake: String = null;
	phoneBrand: String = null;
	phonePrice: String = null;
	paymentMethod: String = null;
	isUpgrade: boolean = false;

	constructor(private http: HttpClient, private headerService: HeaderService, private router: Router) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if(localStorage.getItem('isUpgrade') !== null) {
		this.isUpgrade = true;
		}

		if (localStorage.getItem("postage")) {
			this.postageType = JSON.parse(localStorage.getItem("postage"));
		}

		if (localStorage.getItem("reference")) {
			this.reference = JSON.parse(localStorage.getItem("reference"));
		}

		if(localStorage.getItem('staff-user') != null){
			var user = JSON.parse(localStorage.getItem('staff-user'));
			this.fullName = user['FirstName']+' '+user['LastName'];
		}

		this.IMEI = JSON.parse(localStorage.getItem("cart"))[0]['imei'];

		this.phoneMake = JSON.parse(localStorage.getItem("cart"))[0]['phoneBrand'];
		this.phoneBrand = JSON.parse(localStorage.getItem("cart"))[0]['phoneName'];

		if (JSON.parse(localStorage.getItem("payment"))['paymentType'] == 17) {
			this.paymentMethod = 'Bank'
		}
		else if (JSON.parse(localStorage.getItem("payment"))['paymentType'] == 18) {
			this.paymentMethod = 'PayPal'
		}

		this.phonePrice = JSON.parse(localStorage.getItem("cart"))[0]['price'];




  }

  finishCheckout(){
    localStorage.setItem('cart', "");
    localStorage.setItem('payment', "");
    localStorage.setItem('user', "");
    localStorage.setItem('postage', "");
    localStorage.setItem('newAddress', "");
    localStorage.setItem('reference', "");
	localStorage.setItem('reference', "");
    localStorage.removeItem('promo');
  }

}
