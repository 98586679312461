import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  showForm: Boolean = true;
  password: String = '';
  passwordverify: String = '';
  token: String = null;
  showVerifyError: Boolean = false;
  showSuccess: Boolean = false;
  showFailure: Boolean = false;

  aFormGroup: FormGroup;

  constructor(private route: ActivatedRoute, private router: Router, private ingrammicro: IngrammicroService, private formBuilder: FormBuilder) {
    
  }

  ngOnInit() {
    this.token = this.route.snapshot.params['token'];
  }


  confirmPassword(){
    this.showVerifyError = false;

    if(this.password != null && this.passwordverify != null){
      if(this.password === this.passwordverify){
        this.ingrammicro.createRequest('POST', '/api/Registration/PasswordReset', 'token='+this.token+'&Newpassword='+this.password).subscribe(response => {
          if(response == true){
            this.showForm = false;
            this.showSuccess = true;
          } else {
            this.showForm = false;
            this.showFailure = true;
          }
        });
      } else {
        this.showVerifyError = true;
      }
    }
  }

  navigateHome(){
    this.router.navigateByUrl('/');
  }
}
