import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
	selector: 'app-review',
	templateUrl: './review.component.html',
	styleUrls: ['../cart.component.scss', './review.component.scss']
})
export class ReviewComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	showFormError: boolean = false;
	loading: boolean = false;
	checked: boolean = false;
    promoValue: number = 0;

	staffUser: Object = null;
	user: Object = null;
	userName: String = null;
	userPhone: String = null;
	userEmail: String = null;
	userPaymentMethod: String = null;
    orderRef: string = null;

	paymentSelected: String = null;
	paymentName: String = null;
	paymentNumber: String = null;
	paymentSort: String = null;
	paymentPaypal: String = null;

	errorMessage: String = null;

	remainingAmount: number = null;
	upfrontCost: number = null;
    isUpgrade: boolean = false;

	constructor(private http: HttpClient, private router: Router, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		this.staffUser = JSON.parse(localStorage.getItem("staff-user"));
		
		
		this.user = JSON.parse(localStorage.getItem("user"));
		this.userName = this.user['FirstName'] + ' ' + this.user['LastName']
		this.userPhone = this.user['DaytimeTelNo']
		this.userEmail = this.user['Email'];
		this.orderRef = this.user['OrderRef'];

		if(localStorage.getItem('payment')) {
		this.userPaymentMethod = JSON.parse(localStorage.getItem("payment"))['paymentType'];
		}

		if (localStorage.getItem("payment") && JSON.parse(localStorage.getItem("payment"))['paymentType']) {
			 if (this.userPaymentMethod == '17' || JSON.parse(localStorage.getItem("payment"))['AccountNumber']) {
				this.paymentName = JSON.parse(localStorage.getItem("payment"))['AccountName'];
				this.paymentNumber = JSON.parse(localStorage.getItem("payment"))['AccountNumber'];
				this.paymentSort = JSON.parse(localStorage.getItem("payment"))['AccountSort'];
				this.paymentPaypal = "";
				this.paymentSelected = 'Bank Transfer'
			}
			else if (this.userPaymentMethod == '18' || JSON.parse(localStorage.getItem("payment"))['Paypal']) {
				this.paymentName = "";
				this.paymentNumber = "";
				this.paymentSort = "";
				this.paymentPaypal = JSON.parse(localStorage.getItem("payment"))['Paypal'];
				this.paymentSelected = 'PayPal'
			}
		}
    
		if(localStorage.getItem('isUpgrade') !== null) {
		this.isUpgrade = true;
		this.userPaymentMethod = '25';
		}

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));
			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
			}
		}

    if(localStorage.getItem('promo')) {
      this.promoValue = parseInt(localStorage.getItem('promo'));
    }

		if (localStorage.getItem('new-device-payment')) {
			var newDeviceCosts = JSON.parse(localStorage.getItem('new-device-payment'));
			this.remainingAmount = newDeviceCosts['remaining-amount'];
			this.upfrontCost = newDeviceCosts['upfront-cost'];
		} 

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});
	}

	continueCheckout() {
		if(this.checked) {
      var string;
      if(localStorage.getItem('new-device-payment')) {
        var paymentsplit = {
          paymentType: this.userPaymentMethod,
          AccountNumber: this.paymentNumber,
          AccountName: this.paymentName,
          sortcode: this.paymentSort,
          PaypalEmail: this.paymentPaypal,
          amount: this.remainingAmount
        }
        string = "basketID=" + this.cartContents[0]['basketId'] + "&CustomerID=" + this.user['CustomerID'] + '&StoreUserID=' + this.staffUser['CustomerID'] + "&paymentType=" + 24 + '&paymentsplit=' + JSON.stringify(paymentsplit) + '&newDevicePrice=' + this.upfrontCost;
      } else {
        string = "basketID=" + this.cartContents[0]['basketId'] + "&CustomerID=" + this.user['CustomerID'] + '&StoreUserID=' + this.staffUser['CustomerID'] + '&reference=' + this.user['OrderRef'] +  "&paymentType=" + this.userPaymentMethod + "&AccountNumber=" + this.paymentNumber + "&AccountName=" + this.paymentName + "&sortcode=" + this.paymentSort + "&PaypalEmail=" + this.paymentPaypal;
      }
			this.ingrammicro.createRequest('POST', '/api/TradeIn/CompleteTradeInLite', string).subscribe(response => {
			localStorage.setItem('reference', response['TradeNo']);
				if (response['TradeNo'] == 0) {
					console.log(response);
					this.errorMessage = response['ValidationMessage'];
				} else {
					this.router.navigateByUrl('/cart/complete');
				}
			});
		}
		else {
			this.errorMessage = 'You must check the box above'
		}
	}
}
