import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterService } from 'src/app/global/footer/footer.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { Router } from '@angular/router';

import * as jQuery from 'jquery';

@Component({
  selector: 'app-createmanifest',
  templateUrl: './createmanifest.component.html',
  styleUrls: ['./createmanifest.component.scss']
})
export class CreatemanifestComponent implements OnInit {

  staffId: String = null;
  selected: boolean = false;
  showError: boolean = false;
  carrierReference: String = null;
  continue: boolean = false;
  showCarrierPopup: boolean = false;
  carrierEntered: boolean = false;
  trades: Array<any> = [];
  selectedTrades: Array<any> = [];
  manifestId: String = null;

  showPopupCross: boolean = true;

  constructor(private headerService: HeaderService, private footerService: FooterService, private ingrammicro: IngrammicroService, private router: Router) { }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    if (localStorage.getItem('staff-user') != null) {
      var user = JSON.parse(localStorage.getItem('staff-user'));
      this.staffId = user['CustomerID'];
    }

    this.ingrammicro.createRequest('GET', '/api/Manifest/GetOutstandingTrades?userID=' + this.staffId).subscribe(response => {
      this.trades = response;
    });
  }

  checkIfSelected() {
    // check if tick boxes have been selected, if it has: store selected and continue
    // if not, set showError boolean to true
    // Note: jQuery solution does not work on IE so standard JS being used instead
     // if (jQuery('.selectedDevice:checkbox:checked').length) {
    //   this.selected = true;
    // }

    var selectedDevices = document.querySelectorAll('.selectedDevice:checked');
    var selectedDevicesArray = Array.prototype.slice.call(selectedDevices);
    console.log(selectedDevicesArray);

    if (selectedDevicesArray.length) {
      console.log("Selected set to true since at least one device selected")
      this.selected = true;
    }

    if (this.selected) {
      this.createManifest();
    }
    else {
      this.showError = true;
    }
  }

  toggleTrade(trade) {
    if(this.selectedTrades.indexOf(trade) > -1){
        this.selectedTrades.splice(this.selectedTrades.indexOf(trade), 1);
    } else {
      this.selectedTrades.push(trade);
    }

    console.log(this.selectedTrades);
  }

  saveCarrier() {
    this.showPopupCross = false;
    if(jQuery('.carrier-reference-input').val() === ''){
      jQuery(".carrier-reference-input").css({'border': '1px solid red'});
      return false;
    }

    if(this.manifestId){
      this.ingrammicro.createRequest('POST', '/api/Manifest/ShipManifest', 'ManifestNo='+this.manifestId+'&ConsignmentNo='+this.carrierReference).subscribe(response => {
        if(response == true){
          this.carrierEntered = true;
        } else {
          jQuery("#carrier-error").html("Error: Unable to ship manifest")
        }
      });

    } else {
      jQuery("#carrier-error").html("Error: No Manifest ID")
    }
  }

  closeCarrierPopup() {
    this.showCarrierPopup = false;
    this.carrierEntered = false;
  }

  finishManifest(){
    this.router.navigateByUrl('/dashboard');
  }

  createManifest() {
    if(this.selectedTrades.length >= 1){
      if(this.selectedTrades.length <= 8){
        this.ingrammicro.createRequest('POST', '/api/Manifest/CreateManifest', 'TradeIDString='+JSON.stringify(this.selectedTrades)+'&userid='+this.staffId).subscribe(response => {
          if(response != 0){
            this.showCarrierPopup = true;
            this.manifestId = response;
          } else {
            jQuery(".error").html("Error: Unable to create manifest")
          }
        });
      } else {
        jQuery(".error").html("Please select no more than 8 devices")
      }
    } else {
      jQuery(".error").html("Please select at least one device")
    }
  }
}
