import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import * as jQuery from 'jquery';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HeaderService } from '../header/header.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-loginpopup',
	templateUrl: './loginpopup.component.html',
	styleUrls: ['./loginpopup.component.scss']
})
export class LoginpopupComponent implements OnInit {

	loginUsername: String = null;
	loginPassword: String = null;
	fieldError: boolean = false;
	loading: boolean = false;

	aFormGroup: FormGroup;

	constructor(private http: HttpClient, private router: Router, private ingrammicro: IngrammicroService, private headerService: HeaderService, private formBuilder: FormBuilder) {
		
	}

	ngOnInit() {
	}
	
	login() {
		jQuery(".field-error").remove();
		jQuery(".login-button").css('opacity', '0.5');
		jQuery(".login-button").html("Loading");
		this.loading = true;
			this.ingrammicro.createRequest('POST', `/api/Registration/GetCustomer`, "username=" + this.loginUsername + "&password=" + this.loginPassword).subscribe(response => {
				this.loading = false;
				if (response['CustomerID'] > 0) {
					localStorage.setItem('user', JSON.stringify(response));
					this.router.navigateByUrl('/user/tradein');
					this.headerService.isLoggedIn = true;
					this.closeLoginPopup();
				} else {
					this.fieldError = true;
				}
				jQuery(".login-button").css('opacity', '1');
				jQuery(".login-button").html("Log In");
		})
	}

	closeLoginPopup() {
		jQuery('.loginpopup-overlay').fadeOut();
	}

	showPasswordReset(){
		this.closeLoginPopup();
		this.headerService.setShowPasswordReset(true);
	}
}
