import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterService } from 'src/app/global/footer/footer.service';

declare interface manifestInterface { 

}

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})

export class PrintComponent implements OnInit {

  showLoader: Boolean = false;
  manifestId: String = null;
  manifest: any;
  staffId: String = null;
  today: number = Date.now();

  constructor(private route: ActivatedRoute, private ingrammicro: IngrammicroService, private headerService: HeaderService, private footerService: FooterService) { }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    this.manifestId = this.route.snapshot.params['id'];

    if(localStorage.getItem('staff-user') != null){  
      var user = JSON.parse(localStorage.getItem('staff-user'));
      this.staffId = user['CustomerID'];
    }

    //Load the manifest object
    this.ingrammicro.createRequest('GET', '/api/Manifest/GetItemsOnManifest?ManifestNo='+this.manifestId+'&userid='+this.staffId).subscribe(response => {
      //If the response is valid then set the object and then print the page.
      if(response){
        this.showLoader = false;
        this.manifest = response[Object.keys(response)[0]];
        setTimeout(()=> {
          window.print();
        },1500); 
      }
    });
  }

}
