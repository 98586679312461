import { Component, OnInit } from '@angular/core';
import { FooterService } from '../global/footer/footer.service';
import { HeaderService } from '../global/header/header.service';

@Component({
  selector: 'app-termscondtions',
  templateUrl: './termscondtions.component.html',
  styleUrls: ['./termscondtions.component.scss']
})
export class TermscondtionsComponent implements OnInit {

  constructor(private headerService: HeaderService, private footerService: FooterService) { }

  ngOnInit() {
    this.headerService.setShowHeader(true);
    this.footerService.setShowFooter(true);
  }

}
