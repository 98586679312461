import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';


import * as jQuery from 'jquery';
import * as slider from '../../assets/js/fusion-slider.js';
import { IngrammicroService } from '../ingrammicro.service.js';
import { HeaderService } from '../global/header/header.service.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var Slider: any;

@Component({
	selector: 'app-wearables',
	templateUrl: './wearables.component.html',
	styleUrls: ['./wearables.component.scss'],
	providers: [
		HeaderService
	]
})
export class WearablesComponent implements OnInit {

	searchResults: Array<any> = [];
	searchTerm: String = "";
	searching: boolean = false;
	searchError: boolean = false;
	brands: Array<any> = [];
	selectedBrand: Object = { "name": "", "brand_id": "" };
	brandModels: Array<any> = [];
	fullCollection: Array<any> = [];
	modelSearchTerm: String = "";
    popularModels: Array<any> = [];
	showUnknownImeiPopup: Boolean = false;
	selectedOption: string = '';

	isUpgrade: boolean = false;

	aFormGroup: FormGroup;

	constructor(private http: HttpClient, private sanitization: DomSanitizer, private ingrammicro: IngrammicroService, private headerService: HeaderService,private router: Router, private formBuilder: FormBuilder) {
		
	}

	ngOnInit() {		
		this.headerService.setShowHeader(true);

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if(localStorage.getItem('isUpgrade') !== null) {
			this.isUpgrade = true;
			localStorage.setItem('cart', "");
			localStorage.setItem('payment', "");
			localStorage.setItem('user', "");
			localStorage.setItem('postage', "");
			localStorage.setItem('newAddress', "");
			localStorage.setItem('reference', "");
			localStorage.removeItem('new-device-payment');
			localStorage.removeItem('promo');
		}
		
		this.ingrammicro.createRequest('GET', '/api/Model/GetManufacturersWearables').subscribe(response => {
			var tempBrands = [];

			for (var i = 0; i < Object.keys(response).length; i++) {
				tempBrands.push({ 'brand_id': response[i]['ManufacturerNo'], name: response[i]['ManufacturerName'] });
			}
			this.brands = tempBrands;
			this.selectedBrand = tempBrands[0];
			this.getModels();

			jQuery(document).ready(function () {
				var brandSlider = new Slider(jQuery);
				brandSlider.init('.brand-slider-inner', '.brand-slider-block', null, 9000, false, '.brand-slider', 3, true);
			});

			this.brands.splice(14, 1);
			this.brands.splice(13, 1);
			this.brands.splice(11, 1);
			this.brands.splice(10, 1);
			this.brands.splice(8, 1);
			this.brands.splice(6, 1);

			console.log(this.brands);
		});


		this.ingrammicro.createRequest('GET', '/api/Model/GetPopularModelsWearables').subscribe(response => {
			for (var i = 0; i < Object.keys(response).length; i++) {
				this.popularModels.push({ 'model_id': response[i]['ModelID'], name: response[i]['Model'], "cashvalue": response[i]['CashValue'], "manufacturer": response[i]['Manufacturer'], "imageURL": this.sanitization.bypassSecurityTrustStyle('url(' + response[i]['ImageURL'] + ')') });
			}
			this.popularModels.reverse();

			jQuery(document).ready(function () {
				this.popularSlider = new Slider(jQuery);
				this.popularSlider.init('.phone-slider-container', '.phone-slide-outer', null, 9000, false, '.phone-slider-controls', 3, false);
			});
		});

	}

	bannerSearch() {
		if (!this.searchTerm.match(/[a-z]/i) && this.searchTerm.length > 3) {
			if (this.searchTerm.length == 15) {
				this.searchResults = [];
				this.searchResults = [{ "name": "IMEI Entered: " + this.searchTerm, "url": "" }];
			} else { //All numbers but not 15 characters
				this.searchResults = [{ "name": "IMEI Numbers must be 15 digits long", "url": "" }];
			}
		} else if (this.searchTerm.length < 4) { // Not enough letters, not worth searching for
			this.searchResults = [];
		} else if (this.searchTerm.length >= 10 && !this.searchTerm.includes(' ')) { //search by serial
			this.searchResults = [];
			this.searchResults = [{ "name": "Query Entered: " + this.searchTerm, "url": "/wearables" }];
		} else { // Non-IMEI search term
			this.searchResults = [];

			if (this.searchTerm.length >= 12) {
				this.searching = true;
				this.ingrammicro.createRequest('POST', '/api/IMEI/LookupModelFromIMEI?IMEI=' + this.searchTerm, 'IMEI=' + this.searchTerm).subscribe(response => {
					this.searching = false;
					if (response) {
						//console.log(response);
						this.searchResults.push({ "name": "Serial found: " + response['FullName'], "url": "/viewphone/" + response['ModelID'] + "/" + this.searchTerm, "price": response['CashValue'] });
					} else {
						this.searchResults.push({ "name": "Unknown IMEI. We may not be accepting trade ins for your model. Please contact us for more information.", "url": "" });
					}
				});
			} else {
				this.searching = true;
				this.ingrammicro.createRequest('POST', '/api/Model/GetModelbyModelName', "modelname=" + this.searchTerm).subscribe(response => {
					this.searching = false;
					if (Object.keys(response).length > 0) {
						console.log(response);
						for (var i = 0; i < Object.keys(response).length; i++) {
							if (response[i]['ProductClassNo'] == 15 || response[i]['ProductClassNo'] == 21) {
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewtablet/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							} else if (response[i]['ProductClassNo'] == 1) {
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewphone/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							} else if (response[i]['ProductClassNo'] == 24 || response[i]['ProductClassNo'] == 29) {
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewwearable/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							}
						}
						//this.searchValueSort();
					}
				});
			}
		}
		if(this.searchTerm.length >= 4) {
			this.searchError = false;
		}
	}

	validateIMEI(){
		this.ingrammicro.createRequest('POST', '/api/IMEI/LookupModelFromIMEI?IMEI=' + this.searchTerm).subscribe(response => {
		if (response != null) {

			this.searchResults = [{ "name": response['FullName'], "url": "/viewphone/" + response['ModelID'] + "/" + this.searchTerm , "price": response['CashValue']}];
			if (response['CashValue'] == null) {
				this.searchResults = [{ "name": "Failed to get Model for IMEI", "url": "/phones" }];
			} else {
				if(response['ProductClassNo'] == 15 || response['ProductClassNo'] == 21){
				this.router.navigateByUrl("/viewtablet/" + response['FullNameURLFriendly'] + '/' +  response['ModelID'] + "/" + this.searchTerm)
				} else if (response['ProductClassNo'] == 1) {
				this.router.navigateByUrl("/viewphone/" + response['FullNameURLFriendly'] + '/' + response['ModelID'] + "/" + this.searchTerm)
				} else if (response['ProductClassNo'] == 24 || response['ProductClassNo'] == 29) {
				this.router.navigateByUrl("/viewwearable/" + response['FullNameURLFriendly'] + '/' + response['ModelID'] + "/" + this.searchTerm)
				}
			}
		} else {
			this.searchResults = [{ "name": "Failed to get Model for IMEI", "url": "/wearables" }];
		}
		}, error => {
			this.searchResults = [{ "name": "Failed to get Model for IMEI", "url": "/wearables" }];
		});
	}

	brandPosition() {
		var angular = this;
		setTimeout(function () {
			var currentPosition = jQuery('.brand-slider-inner').attr('id').replace(/[^0-9]/g, '');
			angular.selectedBrand = angular.brands[currentPosition];
			angular.getModels();
		}, 60);
	}

	getModels() {
		this.ingrammicro.createRequest('GET', '/api/Model/GetModelsForManufacturerWearables?ManufacturerNo=' + this.selectedBrand['brand_id']).subscribe(response => {
			var tempModels = [];

			for (var i = 0; i < Object.keys(response).length; i++) {
				tempModels.push({ 'model_id': response[i]['ModelID'], name: response[i]['Model'], "cashvalue": response[i]['CashValue'], "manufacturer": response[i]['Manufacturer'], "imageURL": this.sanitization.bypassSecurityTrustStyle('url(' + response[i]['ImageURL'] + ')') });
			}

			tempModels.sort((a, b) => {
				if (parseFloat(a.cashvalue.replace("£", "")) < parseFloat(b.cashvalue.replace("£", ""))) return -1;
				else if (parseFloat(a.cashvalue.replace("£", "")) > parseFloat(b.cashvalue.replace("£", ""))) return 1;
				else return 0;
			});
			tempModels.reverse();

			this.fullCollection = tempModels;
			this.brandModels = tempModels;
			this.modelSearch();


			this.popularitySort();
		});
	}

	
	searchValueSort() {
		this.searchResults.sort((a, b) => {
			if (parseFloat(a.value.replace("£", "")) < parseFloat(b.value.replace("£", ""))) return -1;
			else if (parseFloat(a.value.replace("£", "")) > parseFloat(b.value.replace("£", ""))) return 1;
			else return 0;
		});
		this.searchResults.reverse();
	}

	popularitySort() {
		jQuery(".sortby-button").removeClass("active");
		jQuery(".sortby-button-pop").addClass("active");
		this.brandModels.sort((a, b) => {
			if (parseFloat(a.cashvalue.replace("£", "")) < parseFloat(b.cashvalue.replace("£", ""))) return -1;
			else if (parseFloat(a.cashvalue.replace("£", "")) > parseFloat(b.cashvalue.replace("£", ""))) return 1;
			else return 0;
		});
		this.brandModels.reverse();
	}

	alphabetSort() {
		jQuery(".sortby-button").removeClass("active");
		jQuery(".sortby-button-alph").addClass("active");
		this.brandModels.sort((a, b) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
			else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
			else return 0;
		});
	}

	changeSortOrder(event: any) {
		this.selectedOption = event.target.value;

		if(this.selectedOption === 'popularity') {
			this.popularitySort();
		}
		else if (this.selectedOption === 'a-z') {
			this.alphabetSort();
		}
	}

	modelSearch() {
		let searchArray = [];
		for (let term of this.modelSearchTerm.split(" ")) {
			if (term) {
				let filters = { name: [term.toLowerCase()] },
					results = this.fullCollection,
					temp = results.filter(({ name }) => filters.name.some(n => name.toLowerCase().includes(n)));
				searchArray.push(temp);
			}
		}

		if (searchArray.length > 1) {
			this.brandModels = [];
			searchArray[0].forEach(secondElement => {
				let exist = searchArray[1].find((item) => item === secondElement);
				if (exist) {
					this.brandModels.push(secondElement);
				}
			});
		} else {
			let filters = { name: [this.modelSearchTerm.toLowerCase()] },
				results = this.fullCollection;
			this.brandModels = results.filter(({ name }) => filters.name.some(n => name.toLowerCase().includes(n)));
		}

		if (jQuery('.sortby-button-alph').hasClass('active')) {
			this.alphabetSort();
		} else {
			this.popularitySort();
		}
  }

  	searchCheck (){
		if (this.searchTerm.length < 4) {
			this.searchError = true;
		}
		else {
			this.searchError = false;
		}
}
  
  	closeImeiPopup(){
		this.showUnknownImeiPopup = false;
	}
}
