import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { HeaderService } from '../global/header/header.service';
import { IngrammicroService } from '../ingrammicro.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	isLoggedIn: Boolean = false;
	loggedInUser: Object = null;
	validPromo: boolean = false;

	promoValue: number = 0;
	promoCode: string = '';
	validationMessage: String = "";
	isUpgrade: boolean = false;
	deviceCondition: Array<any> = [];

  constructor(private router: Router, private route: ActivatedRoute, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }

  ngOnInit() {

		this.headerService.setShowHeader(false);

		this.getCart();

		this.getImages();

		if(localStorage.getItem('promo')) {
			this.promoValue = parseInt(localStorage.getItem('promo'));
		}

		if(localStorage.getItem('isUpgrade') !== null) {
			this.isUpgrade = true;
		}

		jQuery(document).scroll(function() {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position','center '+scrolled+'px');
		});

		if(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0){
			this.isLoggedIn = true;
			this.loggedInUser = JSON.parse(localStorage.getItem("user"));
			console.log(this.loggedInUser);
		}
  }

  clearCart(){
	  localStorage.setItem('cart',"");
    localStorage.removeItem('promo');
	  this.getCart();
  }

  getCart(){
	  if(localStorage.getItem("cart")){
		this.cartContents = JSON.parse(localStorage.getItem("cart"));

		for(let item of this.cartContents){
			this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
		}

	  } else {
		  this.cartContents = [];
		  this.totalCheckout = null;
	  }
  }

	getImages(){
    if(localStorage.getItem('deviceCondition')) {
      this.deviceCondition = JSON.parse(localStorage.getItem('deviceCondition'));
    }
  }

	checkPromo() {
		if (this.promoCode) {
			if (this.cartContents[0]['basketId']) {
				this.ingrammicro.createRequest('POST', '/api/Voucher/ValidateVoucher', 'VoucherCode=' + this.promoCode + '&BasketID=' + this.cartContents[0]['basketId']).subscribe(response => {
					if (response['IsValid'] == true) {
							//Add it immediately
							this.promoValue = response['CashValue'];
							this.verifyPromo();
							this.validPromo = true;
							if (response['CashValue'] == 0) {
								this.validationMessage = response['MessageText'];
							}
						} else {
						this.validationMessage = response['MessageText'];
					}
				});
			} else {
				this.validationMessage = 'No items are in the basket';
			}
		}
	}

	verifyPromo() {
		this.ingrammicro.createRequest('POST', '/api/Voucher/AddVoucher', 'VoucherCode=' + this.promoCode + '&BasketID=' + this.cartContents[0]['basketId'] + '&ValidationField=' + null).subscribe(response => {
			if (response['Success'] == true) {
				//success
				this.validPromo = true;
				localStorage.setItem('promo', JSON.stringify({ code: this.promoCode, value: this.promoValue }));
			} else {
				//error
			}
		});
	}

  progressCart(){
    if(!this.isUpgrade) {
      if(this.promoValue) {
        localStorage.setItem('promo', this.promoValue.toString());
      }
      this.router.navigateByUrl('/cart/payment');
    } else {
      this.router.navigateByUrl('/cart/details');
    }
  }

}
