import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterService } from '../global/footer/footer.service';
import { IngrammicroService } from '../ingrammicro.service';

import * as jQuery from 'jquery';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';


declare interface phoneInterface{
  Name: String;
  Imei: Number;
  UnitCost: String;
  ReceivedDate: String;
  PaymentDate: String;
}

declare interface tradeinInterface{
  PhoneImageURL: String;
  TradeNo: Number;
  DateCreated: String;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  fullName: String = null;
  imei: String = null;
  telephoneNumber: String = null;
  showTrackerPopup: Boolean = false;
  tradein: tradeinInterface;
  phone: phoneInterface;
  userRole: String = 'user';
  customerID: String = null;
  loginEmail: String = null;

  received: boolean = false;
  paymentComplete: boolean = false;

  constructor(private headerService: HeaderService, private footerService: FooterService, private ingrammicro: IngrammicroService, private router: Router) { }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    localStorage.removeItem('isUpgrade');

    if(localStorage.getItem('staff-user') != null){
      var user = JSON.parse(localStorage.getItem('staff-user'));
      this.fullName = user['FirstName']+' '+user['LastName'];
      this.customerID = user['UserID'];
      this.loginEmail = user['Email'];
      
      if(user['RoleID'] == '8921af30-fa81-4ffb-8166-94e438567590'){
        this.userRole = 'admin';
      } else if (user['RoleID'] == 'cb1350b1-f2e1-4abe-bc1a-8e6e0f0fc983') {
        this.userRole = 'store-admin';
      }
      else if (user['RoleID'] == '8e71a38c-b749-4617-8da7-032b0560d3e7') {
        this.userRole = 'store-user';
      }
    }
  }

  submitTracker(){
    if(this.imei || this.telephoneNumber){
      jQuery('.search-input').css('border', 'none');
      jQuery('#search-validation-error').html('Loading...');
      this.ingrammicro.createRequest('POST', '/api/Track/GetTradeInDetailsFromIMEIMobileNo', "IMEI="+this.imei+"&MobileNo="+this.telephoneNumber+"&customerID="+this.customerID+"&email="+this.loginEmail).subscribe(response => {

        this.received = false;
        this.paymentComplete = false;

        this.tradein = response[0];
        this.phone = response[0]['tradeInDetails']['Phones'][0];

        if (this.phone.ReceivedDate !='0') {
          this.received = true;
        }
        if (this.phone.PaymentDate !='0') {
          this.paymentComplete = true;
        }

        this.showTrackerPopup = true;
        jQuery('#search-validation-error').html('');
        if (!this.phone) {
          jQuery('#search-validation-error').html('An invalid IMEI or Mobile Number was provided');
        }
      });
    } else {
      jQuery('#search-validation-error').html('Please provide a IMEI or Mobile Number');
      jQuery('.search-input').css('border', '2px solid red');
    }
  }

  upgrade() {
    localStorage.setItem('isUpgrade', '');
    this.router.navigateByUrl('recycle')
  }

  closePopup(){
    this.showTrackerPopup = false;
  }
}
