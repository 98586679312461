import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router'; // import Router and NavigationEnd

  // declare ga as a function to set and sent the events
 declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class SiteWrapper {
  constructor(public router: Router) {

    // subscribe to router events and send page views to Google Analytics
    // this.router.events.subscribe(event => {

    //   if (event instanceof NavigationEnd) {
        
    //     gtag('config', 'UA-164513945-1', {
    //       'page_title' : event.url,
    //       'page_path': event.url
    //     });
        
    //   }

    // });
  }
  
}
