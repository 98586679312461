import { Component, OnInit } from '@angular/core';
import { FooterService } from 'src/app/global/footer/footer.service';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
  selector: 'app-wrap',
  templateUrl: './wrap.component.html',
  styleUrls: ['./wrap.component.scss']
})
export class WrapComponent implements OnInit {

  applicationDate: Date;
  preAlertNumber: number;
  imei: number;
  product: string;
  value: number;
  storeName: string;
  storeRep: string;
  promo: number = 0;

  constructor(private headerService: HeaderService , private footerService: FooterService, private ingrammicro: IngrammicroService ) { }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    if (localStorage.getItem("staff-user")) {
      var staffUser = JSON.parse(localStorage.getItem("staff-user"));
      this.storeRep = staffUser['FirstName']+' '+staffUser['LastName'];


      this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/GetStoreForUser?UserID='+staffUser['UserID'] + '&email='+staffUser['Email']).subscribe(response => {
        this.storeName = response['SenderName'];
      });
    }

    if (localStorage.getItem("reference")) {
      this.preAlertNumber = JSON.parse(localStorage.getItem("reference"));
    }

    if (localStorage.getItem("user")) {
      var user = JSON.parse(localStorage.getItem("user"));

       this.ingrammicro.createRequest('POST', '/api/TradeIn/GetUsersTradeDetails?UserID='+staffUser['UserID']+'&TradeNo='+this.preAlertNumber+'&email='+staffUser['Email']).subscribe(response => {
         this.applicationDate = response['Datetraded'];
       });
    }

    if (localStorage.getItem("cart")) {
      var cart = JSON.parse(localStorage.getItem("cart"));
      this.product = cart[0]['phoneName'];
      this.imei = cart[0]['imei'];
      this.value = cart[0]['price'];
    }

    if (localStorage.getItem("promo")) {
      var promo = JSON.parse(localStorage.getItem("promo"));
      this.promo = promo.value;
    }

    setTimeout(()=> {
      window.print();
    },2000);
  }

}
