import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterService } from '../global/footer/footer.service';
import { Router } from '@angular/router';
import { IngrammicroService } from '../ingrammicro.service';
import { HomepageLoginService } from './homepage-login.service';

import * as jQuery from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-homepage-login',
  templateUrl: './homepage-login.component.html',
  styleUrls: ['./homepage-login.component.scss']
})
export class HomepageLoginComponent implements OnInit {

  loginUsername: String = null;
  loginPassword: String = null;
  loadAPI: boolean = false;
  showPasswordReset = null;
  loginError: boolean = false;
  errorMessage: String = null;
  aFormGroup: FormGroup;
  interval: any = null;


  constructor(private headerService: HeaderService, private footerService: FooterService, private router: Router, private ingrammicro: IngrammicroService, private login: HomepageLoginService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    if(localStorage.getItem('staff-user') != null){
      this.router.navigateByUrl('/dashboard');
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  processLogin() {
      if(!this.loginUsername || !this.loginPassword) {
        this.loginError = true;
        this.errorMessage = 'Please enter the required fields';
      } else {

        this.loadAPI = true;

        this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/GetStoreUser', "username=" + this.loginUsername + "&password=" + this.loginPassword).subscribe(response => {
          if (response['CustomerID'] > 0) {

            var userRole = 'store-user';
            if(response['RoleID'] == '8921af30-fa81-4ffb-8166-94e438567590'){
              userRole = 'admin';
            } else if (response['RoleID'] == 'cb1350b1-f2e1-4abe-bc1a-8e6e0f0fc983') {
              userRole = 'store-admin';
            }

            localStorage.setItem('staff-user', JSON.stringify(response));
            localStorage.setItem('cart', "");
            localStorage.setItem('promo', "");
            localStorage.setItem('payment', "");
            localStorage.setItem('user', "");
            localStorage.setItem('postage', "");
            localStorage.setItem('newAddress', "");
            localStorage.setItem('reference', "");
            this.router.navigateByUrl('/dashboard');
          } else {
            this.loginError = true;
            this.errorMessage = response['Error'];
            this.loadAPI = false; 
            // DEV ONLY
            // if (location.origin.includes('localhost') || location.origin.includes('three-retail-dev')) {
            //   localStorage.setItem('staff-user', 'test user');
            //   localStorage.setItem('cart', "");
            //   localStorage.setItem('promo', "");
            //   localStorage.setItem('payment', "");
            //   localStorage.setItem('user', "");
            //   localStorage.setItem('postage', "");
            //   localStorage.setItem('newAddress', "");
            //   localStorage.setItem('reference', "");
            //   this.router.navigateByUrl('/dashboard');
            // }
          }
        }, error => {
          this.loadAPI = false;
          this.errorMessage = error['error']['message'];
        });
      }
  }

  closePasswordReset(event){
    this.showPasswordReset = false;
  }
}
