import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import * as jQuery from 'jquery';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronRight, faChevronLeft, faCaretDown, faTimesCircle, faUserCircle, faShoppingBasket, faExclamationTriangle, faLifeRing, faRecycle} from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft as falChevronLeft, faChevronRight as falChevronRight, faSearch, faChevronUp} from '@fortawesome/pro-light-svg-icons';

import { SiteWrapper } from './wrapper.component';
import { HeaderComponent } from './global/header/header.component';
import { FooterComponent } from './global/footer/footer.component';
import { HeaderService } from './global/header/header.service';
import { LoginpopupService } from './global/loginpopup/loginpopup.service';
import { FooterService } from './global/footer/footer.service';
import { RecycleComponent } from './recycle/recycle.component';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { ViewphoneComponent } from './viewphone/viewphone.component';
import { ViewtabletComponent } from './viewtablet/viewtablet.component';
import { MobilesComponent } from './mobiles/mobiles.component';
import { CartComponent } from './cart/cart.component';
import { DetailsComponent } from './cart/details/details.component';
import { PaymentComponent } from './cart/payment/payment.component';
import { CompleteComponent } from './cart/complete/complete.component';
import { BankdetailsComponent } from './cart/bankdetails/bankdetails.component';
import { ReviewComponent } from './cart/review/review.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginpopupComponent } from './global/loginpopup/loginpopup.component';
import { TradeinComponent } from './user/tradein/tradein.component';
import { FaqComponent } from './faq/faq.component';
import { ViewwearableComponent } from './viewwearable/viewwearable.component';
import { TabletsComponent } from './tablets/tablets.component';
import { WearablesComponent } from './wearables/wearables.component';
import { ContactusComponent } from './contactus/contactus.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { TermscondtionsComponent } from './termscondtions/termscondtions.component';
import { RequestComponent } from './user/passwordreset/request/request.component';
import { ConfirmComponent } from './user/passwordreset/confirm/confirm.component';
import { HomepageLoginComponent } from './login/homepage-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RetailHeaderComponent } from './retail-header/retail-header.component';
import { CreateComponent } from './admin/user/create/create.component';
import { DashboardHeaderComponent } from './global/dashboard-header/dashboard-header.component';
import { CreatemanifestComponent } from './admin/manifest/create/createmanifest.component';
import { CompletedManifestsComponent } from './admin/manifest/completed/completed-manifests.component';
import { PortalHeaderComponent } from './global/portal-header/portal-header.component';
import { ViewComponent } from './admin/user/view/view.component';
import { PrintComponent } from './admin/manifest/print/print.component';
import { HomepageLoginService } from './login/homepage-login.service';
import { DeliveryComponent } from './cart/delivery/delivery.component';
import { ReceiptComponent } from './cart/receipt/receipt.component';
import { WrapComponent } from './cart/wrap/wrap.component';
import {WebcamModule} from 'ngx-webcam';
import { ContributionComponent } from './cart/contribution/contribution.component';
import { CameraComponent } from './cart/camera/camera.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { IngrammicroService } from './ingrammicro.service';


const siteRoutes: Routes = [
  {path: '', component: HomepageLoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'recycle', component: RecycleComponent},
  {path: 'how-it-works', component: HowitworksComponent},
  {path: 'admin/user/create', component: CreateComponent},
  {path: 'admin/user/view', component: ViewComponent},
  {path: 'admin/manifest/create', component: CreatemanifestComponent},
  {path: 'admin/manifest/completed', component: CompletedManifestsComponent},
  {path: 'admin/manifest/print/:id', component: PrintComponent},
  {path: 'faqs', component: FaqComponent},
  {path: 'viewphone/:model/:id', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/details', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/:imei', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/:imei/details', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/:imei/details', component: ViewphoneComponent},
  {path: 'viewphone/:id', component: ViewphoneComponent},
  {path: 'viewphone/:id/:imei', component: ViewphoneComponent},
  {path: 'viewtablet/:model/:id', component: ViewtabletComponent},
  {path: 'viewtablet/:model/:id/:imei', component: ViewtabletComponent},
  {path: 'viewtablet/:model/:id/:imei/details', component: ViewtabletComponent},
  {path: 'viewtablet/:id', component: ViewtabletComponent},
  {path: 'viewtablet/:id/:imei', component: ViewtabletComponent},
  {path: 'viewwearable/:model/:id', component: ViewwearableComponent},
  {path: 'viewwearable/:model/:id/:imei', component: ViewwearableComponent},
  {path: 'viewwearable/:model/:id/:imei/details', component: ViewwearableComponent},
  {path: 'viewwearable/:id', component: ViewwearableComponent},
  {path: 'viewwearable/:id/:imei', component: ViewwearableComponent},
  {path: 'cart', component: CartComponent},
  {path: 'cart/camera', component: CameraComponent},
  {path: 'cart/details', component: DetailsComponent},
  {path: 'cart/bankdetails', component: BankdetailsComponent},
  {path: 'cart/payment', component: PaymentComponent},
  {path: 'cart/review', component: ReviewComponent},
  {path: 'cart/delivery', component: DeliveryComponent},
  {path: 'cart/complete', component: CompleteComponent},
  {path: 'cart/receipt', component: ReceiptComponent},
  {path: 'cart/wrap', component: WrapComponent},
  {path: 'cart/contribution', component: ContributionComponent},
  {path: 'contact-us', component: ContactusComponent},
  {path: 'phones', component: MobilesComponent},
  {path: 'tablets', component: TabletsComponent},
  {path: 'wearables', component: WearablesComponent},
  {path: 'user/tradein', component: TradeinComponent},
  {path: 'user/passwordreset/confirm/:token', component: ConfirmComponent},
  {path: 'privacy-policy', component: PrivacypolicyComponent},
  {path: 'cookie-policy', component: CookiepolicyComponent},
  {path: 'terms', component: TermscondtionsComponent},
  {path: 'homepage-login', component: HomepageLoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: '**', component: HomepageLoginComponent}
];

@NgModule({
  declarations: [
	SiteWrapper,
	HeaderComponent,
	FooterComponent,
	RecycleComponent,
	HowitworksComponent,
	ViewphoneComponent,
	MobilesComponent,
	CartComponent,
	DetailsComponent,
  PaymentComponent,
  DeliveryComponent,
	CompleteComponent,
	BankdetailsComponent,
	ReviewComponent,
	LoginpopupComponent,
	TradeinComponent,
  FaqComponent,
  ViewtabletComponent,
  ViewwearableComponent,
  TabletsComponent,
  WearablesComponent,
  ContactusComponent,
  PrivacypolicyComponent,
  CookiepolicyComponent,
  TermscondtionsComponent,
  RequestComponent,
  ConfirmComponent,
  HomepageLoginComponent,
  CreateComponent,
  DashboardComponent,
  RetailHeaderComponent,
  DashboardHeaderComponent,
  CreatemanifestComponent,
  CompletedManifestsComponent,
  PortalHeaderComponent,
  ViewComponent,
  PrintComponent,
  ReceiptComponent,
  WrapComponent,
  ContributionComponent,
  CameraComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(siteRoutes, {scrollPositionRestoration: 'enabled'}),
	  FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    WebcamModule,
    NgxCaptchaModule
  ],
  providers: [HeaderService, FooterService, LoginpopupService, HomepageLoginService],
  bootstrap: [SiteWrapper],


})
export class AppModule{
  constructor(private router: Router, library: FaIconLibrary, private ingrammicro: IngrammicroService){

    library.addIcons(falChevronLeft, falChevronRight, faSearch, faChevronUp, faCaretDown, faTimesCircle, faUserCircle, faShoppingBasket, faExclamationTriangle, faLifeRing, faRecycle);

    
    setInterval(()=> {
      //if expiry token was created 30 minutes ago, remove recaptcha token
      if(localStorage.getItem('recaptcha_expiry')){
        if(Number(localStorage.getItem('recaptcha_expiry')) < Date.now()) {
          localStorage.removeItem('recaptcha');
          localStorage.removeItem('recaptcha_expiry');
        }
      }

      // if user is not blocked via 429/403 and the user's token has expired, ensure page refreshes so new token grabbed
      if (!ingrammicro.getCookie('user_blocked')){
        if(!ingrammicro.getCookie('token')){
          console.warn('token expired, reloading');
          setTimeout(()=>(
            window.location.reload()
          ), 2500)
        }
      }
    },1000);
  }
}

// Node cannot be found in the current page
