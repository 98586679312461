import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { HeaderService } from '../../global/header/header.service';

import * as jQuery from 'jquery';
import { Observable, Subject } from 'rxjs';
import { IngrammicroService } from 'src/app/ingrammicro.service';


@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['../cart.component.scss','./camera.component.scss']
})
export class CameraComponent implements OnInit {

  cartContents: Array<any> = [];
	totalCheckout: number = null;
	isLoggedIn: Boolean = false;
  loggedInUser: Object = null;

  frontImageUrl: string = '';
  backImageUrl: string = '';

  deviceCondition: Array<any> = [];

  showBack: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }

  ngOnInit() {

	this.headerService.setShowHeader(false);

	this.getCart();

	jQuery(document).scroll(function() {
		var scrolled = (jQuery(document).scrollTop() / 2);
		jQuery('.anim-banner').css('background-position','center '+scrolled+'px');
	});

	if(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0){
		this.isLoggedIn = true;
		this.loggedInUser = JSON.parse(localStorage.getItem("user"));
		console.log(this.loggedInUser);
	}
  }

  clearCart(){
	  localStorage.setItem('cart',"");
	  this.getCart();
  }

  getCart(){
	  if(localStorage.getItem("cart")){
    this.cartContents = JSON.parse(localStorage.getItem("cart"));
    console.log(this.cartContents)

		for(let item of this.cartContents){
			this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
		}

	  } else {
		  this.cartContents = [];
		  this.totalCheckout = null;
	  }
  }

  progressCart(){

    this.deviceCondition =  [{ "pictureType": 1 , "pictureString": this.frontImageUrl}, { "pictureType": 2 , "pictureString" : this.backImageUrl }];


    var object = {
      "BasketID" : this.cartContents[0]['basketId'],
      "pictureList"  : this.deviceCondition
    }
    console.log(object);

			this.ingrammicro.createRequest('POST', '/api/Quotation/SavePicture', 'BasketID='+this.cartContents[0]['basketId']+'&pictureList='+JSON.stringify(this.deviceCondition)).subscribe(response => {
        if(response == "true"){

        } else {
          console.log('Error uploading images');
        }
			});

    localStorage.setItem('deviceCondition', JSON.stringify(this.deviceCondition));



    this.router.navigateByUrl('/cart');
  }

  private trigger: Subject<void> = new Subject<void>();

  triggerSnapshot(): void {
    this.trigger.next();
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  captureImageFront(image) {
    console.log(this.cartContents);
    this.frontImageUrl = image.imageAsDataUrl;
  }

  captureImageBack(image) {
    console.log(this.cartContents);
    this.backImageUrl = image.imageAsDataUrl;
  }



}
