import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import {Location} from '@angular/common';
import { HttpClient } from '@angular/common/http';


declare var jQuery: any;

@Component({
  selector: 'site-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showFooter = null;

  constructor(private footerService: FooterService, private _location: Location, private http: HttpClient) { }

  ngOnInit() {
    this.footerService.showFooter.subscribe(showFooter => {
      this.showFooter = showFooter;
    })
  }


}