import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { parse } from '@fortawesome/fontawesome-svg-core';
import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';


@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['../cart.component.scss', './payment.component.scss']
})
export class PaymentComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
  totalPromo: number = null;
	selectedPayment: String = null;
	showPopup: Boolean = false;
	showBillPopup: Boolean = false;
	showPaypalPopup: Boolean = false;
	billTelephone: String = null;
	paypalemail: String = null;
	billingCheckbox: String = null;
	continueError: string = null;
  showCalcError: boolean = false;
  promoValue: number = 0;

  isBuying: boolean = null;
  isNextStep: boolean = false;
	newDeviceCost: number = null;
	amountDeviceCost: number = null;
	newDeviceFinalCost: number = 0.00;
	newDeviceNumber: string = null;
  newDeviceError: string = null;

	remainingAmount: number = null;
  remainingTotal: number = null;

	calcError: string = null;

	constructor(private router: Router, private headerService: HeaderService) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

    if(localStorage.getItem('promo')) {
      this.promoValue = parseInt(localStorage.getItem('promo'));
    }

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));

			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
				this.remainingAmount = this.totalCheckout;
			}

		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});
	}

  nextStep() {
    this.isNextStep = true;
    if(this.isBuying) {
      this.calculateNewDeviceCost();
    }
  }

	calculateNewDeviceCost() {
    this.continueError = null;
    this.totalPromo = parseFloat((this.totalCheckout + this.promoValue).toFixed(2));
		if (this.amountDeviceCost > this.totalPromo) {
			this.calcError = "This value cannot exceed £" + this.totalPromo;
		} else if(this.newDeviceCost < this.amountDeviceCost) {
      this.calcError = "New device value cannot be less than the amount going towards new device"
    } else {
			this.calcError = null;
			this.newDeviceFinalCost = this.newDeviceCost - this.amountDeviceCost;
			this.remainingAmount = this.totalCheckout - this.amountDeviceCost;
      this.remainingTotal = parseFloat((this.remainingAmount + this.promoValue).toFixed(2));
		}
	}

	selectMethod(method) {
		this.selectedPayment = method;
	}

	checkoutContinue() {
    if(this.isBuying) {
      if(!this.newDeviceNumber) {
        this.newDeviceError = 'MSISDN number is required';
        return;
      }
      if(this.newDeviceNumber.length !== 11 || /[a-zA-Z]/g.test(this.newDeviceNumber)) {
        this.newDeviceError = 'Invalid MSISDN number';
        return;
      }
      this.newDeviceError = null;
    } else {
      localStorage.removeItem('new-device-payment');
    }
		if (this.selectedPayment != null) {
			this.continueError = null;
			if (this.selectedPayment == "banktransfer") {
				localStorage.setItem('payment', JSON.stringify({ "paymentType": 2 }));
				if (this.amountDeviceCost > 0 && this.isBuying) {
					localStorage.setItem('new-device-payment', JSON.stringify({ "remaining-amount": this.remainingAmount, "upfront-cost": this.newDeviceFinalCost}))
				}
				this.router.navigateByUrl('/cart/bankdetails');
			} else if (this.selectedPayment == "cheque") {
				localStorage.setItem('payment', JSON.stringify({ "paymentType": 1 }));
				this.router.navigateByUrl('/cart/delivery');
			} else if (this.selectedPayment == "billcredit") {
				this.openBillPopup();
			} else if (this.selectedPayment == "paypal") {
				this.openPaypalPopup();
			} else {
				console.error('Invalid payment method selected');
			}
		} else if (this.remainingTotal == 0 && this.isBuying){
      localStorage.setItem('new-device-payment', JSON.stringify({ "remaining-amount": this.remainingAmount, "upfront-cost": this.newDeviceFinalCost}))
			this.router.navigateByUrl('/cart/details');
    } else {
			this.continueError = 'Please select how you would like to be paid';
		}
	}

	openChequePopup() {
		this.showPopup = true;
		this.showBillPopup = true;
	}

	confirmChequeDetails() {
		localStorage.setItem('payment', JSON.stringify({ "paymentType": 1 }));
		this.router.navigateByUrl('/cart/delivery');
	}

	openBillPopup() {
		this.showPopup = true;
		this.showBillPopup = true;
	}

	confirmBillDetails() {
		localStorage.setItem('payment', JSON.stringify({ "paymentType": 9, "O2Number": this.billTelephone }));
		this.router.navigateByUrl('/cart/delivery');
	}

	openPaypalPopup() {
		this.showPopup = true;
		this.showPaypalPopup = true;
	}

	confirmPaypalDetails(){
		localStorage.setItem('payment', JSON.stringify({ "paymentType": 18, "Paypal": this.paypalemail }));
		if (this.amountDeviceCost > 0 && this.isBuying) {
			localStorage.setItem('new-device-payment', JSON.stringify({ "remaining-amount": this.remainingAmount, "upfront-cost": this.newDeviceFinalCost}))
		}
		this.router.navigateByUrl('/cart/details');
	}

	closePopup(){
		this.showPopup = false;
		this.showBillPopup = false;
		this.showPaypalPopup = false;
	}

	toggleBillingCheckbox(){
		if(this.billingCheckbox == 'active'){
			this.billingCheckbox = null;
		} else {
			this.billingCheckbox = 'active';
		}
	}
}
