import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
	selector: 'app-delivery',
	templateUrl: './delivery.component.html',
	styleUrls: ['../cart.component.scss', './delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	selectedPostage: String = null;
	finalError: String = null;
	loggedInUser: Object = null;
	postageId: Number = null;
	paymentType: String = "";
	paymentName: String = "";
	paymentNumber: String = "";
	paymentSort: String = "";
	popupShow: Boolean = false;
	paymentPaypal: String = "";
	paymentO2Number: String = "";
	showContinueError: boolean = false;
	loading: boolean = false;
  promoValue: number = 0;

	constructor(private http: HttpClient, private router: Router, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }



	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));

			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
			}

		}

    if(localStorage.getItem('promo')) {
      this.promoValue = parseInt(localStorage.getItem('promo'));
    }

		if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0) {
			this.loggedInUser = JSON.parse(localStorage.getItem("user"));
		}

		if (localStorage.getItem("payment") && JSON.parse(localStorage.getItem("payment"))['paymentType']) {
			this.paymentType = JSON.parse(localStorage.getItem("payment"))['paymentType'];
			if (JSON.parse(localStorage.getItem("payment"))['paymentType'] == 1) {
				this.paymentName = "";
				this.paymentNumber = "";
				this.paymentSort = "";
				this.paymentPaypal = "";
				this.paymentO2Number = "";
			} else if (JSON.parse(localStorage.getItem("payment"))['paymentType'] == 2) {
				this.paymentName = JSON.parse(localStorage.getItem("payment"))['AccountName'];
				this.paymentNumber = JSON.parse(localStorage.getItem("payment"))['AccountNumber'];
				this.paymentSort = JSON.parse(localStorage.getItem("payment"))['AccountSort'];
				this.paymentPaypal = "";
				this.paymentO2Number = "";
			} else if (JSON.parse(localStorage.getItem("payment"))['paymentType'] == 9) {
				this.paymentName = "";
				this.paymentNumber = "";
				this.paymentSort = "";
				this.paymentPaypal = "";
				this.paymentO2Number = JSON.parse(localStorage.getItem("payment"))['O2Number'];
			} else if (JSON.parse(localStorage.getItem("payment"))['paymentType'] == 13) {
				this.paymentName = "";
				this.paymentNumber = "";
				this.paymentSort = "";
				this.paymentPaypal = JSON.parse(localStorage.getItem("payment"))['Paypal'];
				this.paymentO2Number = "";
			}
		}

	}

	openPopup() {
		this.popupShow = true;
	}

	closePopup() {
		this.popupShow = false;
	}

	postContinue() {
		localStorage.setItem('postage', "2");
		this.postageId = 2;
		this.sendCheckout();
		this.showContinueError = false;
	}

	freepostContinue() {
		localStorage.setItem('postage', "1");
		this.postageId = 1;
		this.sendCheckout();
		this.showContinueError = false;
	}

	selectMethod(method) {
		this.selectedPostage = method;
	}

	checkoutContinue() {
		if (this.selectedPostage != null) {
			if (this.selectedPostage == "post") {
				localStorage.setItem('postage', "2");
				this.postageId = 2;
				this.sendCheckout();
			} else {
				localStorage.setItem('postage', "1");
				this.postageId = 1;
				this.sendCheckout();
			}
			this.showContinueError = false;


		} else {
			this.showContinueError = true;
		}

	}

	sendCheckout() {
		// Below sends first item only
		this.loading = true;
		var string = "basketID=" + this.cartContents[0]['basketId'] + "&CustomerID=" + this.loggedInUser['CustomerID'] + "&paymentType=" + this.paymentType + "&CollectionType=" + this.postageId + "&AccountNumber=" + this.paymentNumber + "&AccountName=" + this.paymentName + "&sortcode=" + this.paymentSort + "&PaypalEmail=" + this.paymentPaypal + "&AccountMobileNo=" +this.paymentO2Number;
		this.ingrammicro.createRequest('POST', '/api/TradeIn/CompleteTradeInLite', string).subscribe(response => {
			localStorage.setItem('reference', response['TradeNo']);
			if (response['TradeNo'] == 0) {
				this.finalError = response['ValidationMessage'];
				this.loading = false;
			} else {
				this.router.navigateByUrl('/cart/complete');
			}
		});
	}

}
