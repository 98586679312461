import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class FooterService {

    public showFooter = new BehaviorSubject('ShowFooter');
  
    constructor() { }

    setShowFooter(showFooter){
        this.showFooter.next(showFooter);
    }

}