import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../global/header/header.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {style, state, animate, transition, trigger} from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0}),
            animate('300ms ease-out',
                style({opacity: '*'}))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: '*'}),
            animate('0ms ease-out',
                style({opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class FaqComponent implements OnInit {

  information: String = null;
  answers: Array<boolean> = [];

  constructor(private headerService: HeaderService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('FAQs |  Three');
		//this.meta.addTag({name: 'keywords', content: 'Angular Project, Create Angular Project'});

    this.headerService.setShowHeader(true);
  }
}
