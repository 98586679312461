import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as jQuery from 'jquery';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HeaderService } from 'src/app/global/header/header.service';

@Component({
	selector: 'app-tradein',
	templateUrl: './tradein.component.html',
	styleUrls: ['./tradein.component.scss']
})
export class TradeinComponent implements OnInit {

	isLoggedIn: Boolean = false;
	loggedInUser: Object = null;
	tradeins: Object = [];
	showLoader: Boolean = true;

	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private ingrammicro: IngrammicroService, private headerService: HeaderService) { }

	ngOnInit() {
		if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0) {
			this.isLoggedIn = true;
			this.loggedInUser = JSON.parse(localStorage.getItem("user"));
			console.log(this.loggedInUser);
		}

		this.ingrammicro.createRequest('GET', '/api/Track/GetTradeIns?customerID=' + this.loggedInUser['CustomerID']).subscribe(response => {
			this.tradeins = response;
			this.showLoader = false;
		});
	}

	toggleTracker(event) {
		var element = event.target;
		jQuery(element).toggleClass("active");
		jQuery(element).parent().parent().parent().toggleClass("active");
		console.log(jQuery(element).parent().parent().parent());
	}

	logoutLink() {
		localStorage.setItem('user', "");
		this.headerService.isLoggedIn = false;
		this.router.navigateByUrl('/');
	}

}
