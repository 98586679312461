import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class HomepageLoginService {

    public showPasswordReset = new BehaviorSubject('ShowPasswordReset');

    constructor() { }

    setShowPasswordReset(showPasswordReset){
        this.showPasswordReset.next(showPasswordReset);
    }
}
