import { Component, OnInit } from '@angular/core';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  userLoggedIn : boolean;
  hrefLink: String;

  constructor(private router: Router) {

  }

  ngOnInit() {
    if( window.location.pathname != '/') {
      this.hrefLink = "/dashboard"
    } else {
      this.hrefLink = "/"
    }

    if(localStorage.getItem('staff-user') != null){
      this.userLoggedIn = true;
    }
    else {
      this.userLoggedIn = false;
    }
  }

  logOut() {
    localStorage.removeItem('staff-user');
    localStorage.setItem('cart', "");
    localStorage.setItem('payment', "");
    localStorage.setItem('user', "");
    localStorage.setItem('postage', "");
    localStorage.setItem('newAddress', "");
    localStorage.setItem('reference', "");
    this.router.navigate(['/login']);
  }

}
