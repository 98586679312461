import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HeaderService } from 'src/app/global/header/header.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['../cart.component.scss', './details.component.scss']
})
export class DetailsComponent implements OnInit {

	addressLine1: String = null;
	addressLine2: String = null;
	addressTown: String = null;
	addressPostcode: string = null;
	addressResults: Array<{ "addressLine2": string, "addressTown": string, "addressPostcode": string }> = [];
	addressesShown: boolean = false;
	showAddressFinder:boolean = false;
	searchQuery: String = null;
	isLoggedIn: Boolean = false;
	loggedInUser: Object = null;
	autofill: Boolean = false;
	cartContents: Array<any> = [];
	totalCheckout: number = null;

	userAddressLine1: String = null;
	userAddressLine2: String = null;
	userAddressTown: String = null;
	userAddressPostcode: String = null;

	checkoutFirstname: String = null;
	checkoutLastname: String = null;
	checkoutPhone: String = null;
	checkoutEmail: String = null;
	checkoutEmailConf: String = null;
	checkoutPassword: String = null;
	checkoutPasswordConf: String = null;
	orderRef: string = null;

	accountPasswordShown: boolean = false;
	confirmPasswordShown: boolean = false;
	showFormError: boolean = false;
	apiError: String = '';

	promoValue: number = 0;
	remainingAmount: number = null;
	upfrontCost: number = null;
	isUpgrade: boolean = false;
  
	aFormGroup: FormGroup;

	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private headerService: HeaderService, private ingrammicro: IngrammicroService, private formBuilder: FormBuilder) {
		
	}

	ngOnInit() {
		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));
			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
			}
		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

    if(localStorage.getItem('isUpgrade') !== null) {
      this.isUpgrade = true;
			this.checkoutEmail = 'threestore@three.co.uk';
			this.loggedInUser = JSON.parse(localStorage.getItem("staff-user"));
			this.ingrammicro.createRequest('GET', '/api/RetailUserManagement/GetUserStoreAddress?email=' + this.loggedInUser['Email'] + '&UserID=' + this.loggedInUser['UserID']).subscribe(response => {
				this.addressLine1 = response['Address1']
				this.addressTown = response['Address4']
				this.addressPostcode = response['PostCode']
			}, error => {
				console.log('error getting store address for user');
			});
    }

		if (localStorage.getItem('new-device-payment')) {
			var newDeviceCosts = JSON.parse(localStorage.getItem('new-device-payment'));
			this.remainingAmount = newDeviceCosts['remaining-amount'];
			this.upfrontCost = newDeviceCosts['upfront-cost'];
		} 

    if(localStorage.getItem('promo')) {
      this.promoValue = parseInt(localStorage.getItem('promo'));
    }

		if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0) {
			this.isLoggedIn = true;

			if (!localStorage.getItem("newAddress")) {
				var existingAddress = JSON.parse(localStorage.getItem("user"))['PackDeliveryAddress'];
				this.userAddressLine1 = existingAddress['Address1'];
				this.userAddressLine2 = existingAddress['Address2'];
				this.userAddressTown = existingAddress['City'];
				this.userAddressPostcode = existingAddress['PostCode'];
			} else {
				var newAddress = JSON.parse(localStorage.getItem("newAddress"));
				this.userAddressLine1 = newAddress['line1'];
				this.userAddressLine2 = newAddress['line2'];
				this.userAddressTown = newAddress['town'];
				this.userAddressPostcode = newAddress['postcode'];
			}
		}

	}

	getAddresses() {
		this.http.get('https://api.ideal-postcodes.co.uk/v1/postcodes/' + this.searchQuery + '/?api_key=ak_jwgh8ozzaKXDP2OQgKxvF7rjQdulN').subscribe(response => {
			this.addressResults = response['result'];
			jQuery('.address-finder-results').css('border-bottom-width', '1px');
			this.addressesShown = true;
		});

	}

	selectAddress(addressLine1, addressLine2, addressTown, addressPostcode) {
		this.addressLine1 = addressLine1;
		this.addressLine2 = addressLine2;
		this.addressTown = addressTown;
		this.addressPostcode = addressPostcode;
		this.addressResults = [];
		this.autofill = true;
		jQuery('.address-finder-form').css('display', 'none');
		jQuery('.address-finder-results').css('border-bottom-width', '0');
		jQuery('.logged-in-address').hide();
		jQuery(".address-error").hide();
		jQuery(".autofill-address").hide();
		jQuery(".autofill-address").fadeIn();
	}

	logOut() {
		this.isLoggedIn = false;
		this.loggedInUser = null;
		this.addressResults = [];
		this.addressLine1 = null;
		this.addressLine2 = null;
		this.addressTown = null;
		this.addressPostcode = null;
		this.checkoutFirstname = null;
		this.checkoutLastname = null;
		this.checkoutPhone = null;
		this.checkoutEmail = null;
		this.checkoutEmailConf = null;
		this.checkoutPassword = null;
		this.checkoutPasswordConf = null;
		this.autofill = false;
		jQuery(".autofill-address").hide();
		jQuery('.address-finder-form').css('display', 'block');
		localStorage.setItem('user', "");
	}

	showAutofill() {
		jQuery(".autofill-address").hide();
		jQuery(".autofill-address").fadeIn();
		jQuery('.logged-in-address').hide();
		this.autofill = true;
		this.addressResults = [];
		this.addressLine1 = null;
		this.addressLine2 = null;
		this.addressTown = null;
		this.addressPostcode = jQuery(".address-finder-form input").val().toString();

	}

	toggleAccountPassword () {
		this.accountPasswordShown = !this.accountPasswordShown;
	}

	toggleConfirmPassword () {
		this.confirmPasswordShown = !this.confirmPasswordShown;
	}

	continueCheckout() {

		var errors = 0;
		jQuery(".field-error").remove();
		jQuery(".details-field-error").remove();

		if (!this.checkoutFirstname) {
			jQuery(".checkout-firstname").after("<div class='details-field-error'>Please fill in the above field</div>");
			errors = 1;
		}
		if (!this.checkoutLastname) {
			jQuery(".checkout-lastname").after("<div class='details-field-error'>Please fill in the above field</div>");
			errors = 1;
		}
		if (!this.checkoutPhone) {
			jQuery(".checkout-phone").after("<div class='details-field-error'>Please fill in the above field</div>");
			errors = 1;
		}
		if (!this.checkoutEmail && !this.isUpgrade) {
			jQuery(".checkout-email").after("<div class='details-field-error'>Please fill in the above field</div>");
			errors = 1;
		}
		if (!this.checkoutEmailConf && !this.isUpgrade) {
			jQuery(".checkout-emailconf").after("<div class='details-field-error'>Please fill in the above field</div>");
			errors = 1;
		} else if (this.checkoutEmailConf != this.checkoutEmail && !this.isUpgrade) {
			jQuery(".checkout-emailconf").after("<div class='details-field-error'>This does not match the above Email address</div>");
			errors = 1;
		}

		if (this.autofill == false && !this.isUpgrade) {
			jQuery(".address-finder-form").after("<div class='field-error address-field-error'>Please select an address above, or enter a new one</div>");
			errors = 1;
		} else {
			if ((!this.addressLine1 || !this.addressTown || !this.addressPostcode) && !this.isUpgrade) {
				jQuery(".autofill-address").after("<div class='field-error address-field-error'>Please fill in the above fields to continue</div>");
				errors = 1;
			}
		}

    if(!this.orderRef && this.isUpgrade) {
      jQuery(".checkout-ref").after("<div class='details-field-error'>Please fill in the above field</div>");
      errors = 1;
    }


		if (errors == 0) {
			jQuery(".create-account-button").css('opacity', '0.5');
			jQuery(".create-account-button").html("Loading");
			var address = { "AddressID": 1, "Name": "Postage Address", "Address1": this.addressLine1, "Address2": this.addressLine2, "City": this.addressTown, "PostCode": this.addressPostcode };
			var request = 'firstName=' + this.checkoutFirstname + '&surname=' + this.checkoutLastname + '&email=' + this.checkoutEmail + '&mobileTelNumber=' + this.checkoutPhone + '&daytimeTelNumber=' + this.checkoutPhone + '&packAddressString=' + JSON.stringify(address);
			console.log(address);
			this.ingrammicro.createRequest('POST', '/api/Registration/Register', request).subscribe(response => {
				if (response['IsValid'] != true) {
					jQuery(".field-error").remove();
					jQuery(".create-account-button").after("<div class='field-error'>" + response['ValidationMessage'] + "</div>");
					jQuery(".create-account-button").css('opacity', '1');
					jQuery(".create-account-button").html("Continue");
				} else {
					var newUser = {
					'FirstName': this.checkoutFirstname,
					'LastName': this.checkoutLastname,
					'FullName': this.checkoutFirstname + ' ' + this.checkoutLastname,
					'Email': this.checkoutEmail,
					'DaytimeTelNo': this.checkoutPhone,
					'CustomerID': response['CustomerID'],
					'OrderRef': this.orderRef,
					'packAddressString': JSON.stringify(address) }
					localStorage.setItem('user', JSON.stringify(newUser));
					this.router.navigateByUrl('/cart/review');
				}
			}, error => {
				jQuery(".field-error").remove();
				jQuery(".create-account-button").after("<div class='field-error'>" + error['ValidationMessage'] + "</div>");
				jQuery(".create-account-button").css('opacity', '1');
				jQuery(".create-account-button").html("Continue");
			});
		}
	}
}
