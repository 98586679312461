import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterService } from 'src/app/global/footer/footer.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
  selector: 'app-completed-manifests',
  templateUrl: './completed-manifests.component.html',
  styleUrls: ['./completed-manifests.component.scss']
})
export class CompletedManifestsComponent implements OnInit {
  
  staffId: String = null;
  view:boolean = false;
  manifests: Array<any> = [];
  selectedManifest: String = null;
  trades: Array<any> = [];
  selectedManifestNumber: String = null;


  constructor(private headerService: HeaderService, private footerService: FooterService, private ingrammicro:IngrammicroService) { }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    if(localStorage.getItem('staff-user') != null){  
      var user = JSON.parse(localStorage.getItem('staff-user'));
      this.staffId = user['CustomerID'];
    }

    this.ingrammicro.createRequest('GET', '/api/Manifest/GetManifestsForStore?userID='+this.staffId).subscribe(response => {
      if(response['ManifestSummary']){
        this.manifests = response['ManifestSummary']['storeManifestList'];
      } else {
        console.log('ERROR LOADING MANIFESTS');
      }
    });
  }

  openManifest(manifest){
    if(manifest['ManifestNo']){
      this.ingrammicro.createRequest('GET', '/api/Manifest/GetItemsOnManifest?ManifestNo='+manifest['ManifestNo']+'&userid='+this.staffId).subscribe(response => {
        this.trades = response['StoreManifestDetail'];
        this.selectedManifestNumber = manifest['ManifestNo'];
        this.view = true;
      });
    } else {
      console.log('ERROR MISSING MANIFEST NUMBER');
    }

    //Load manifest by id.

  }

  printManifest(){
    window.print();
  }

}
