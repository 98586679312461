import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { LoginpopupService } from '../loginpopup/loginpopup.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
	selector: 'site-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
	]
})
export class HeaderComponent implements OnInit {

	showHeader = null;
	showPasswordReset = null;
	showCookiePopup: Boolean = false;
	showCartDot: Boolean = false;
	showMobileMenu = false;
    showAPIBlockPopup = null;

	constructor(private headerService: HeaderService, private _location: Location, private http: HttpClient, private router: Router, private route: ActivatedRoute, private ingrammicro: IngrammicroService) { }

	ngOnInit() {
		if(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0){
			this.headerService.isLoggedIn = true;
		}

		this.headerService.showHeader.subscribe(showHeader => {
			this.showHeader = showHeader;
		});

		this.headerService.showPasswordReset.subscribe(showPasswordReset => {
			this.showPasswordReset = showPasswordReset;
		});

		this.headerService.showAPIBlockPopup.subscribe(showAPIBlockPopup => {
			this.showAPIBlockPopup = showAPIBlockPopup;
		})

		jQuery(document).on("click", ".nav-link a", function () {
			jQuery('header').removeClass('menu-open');
		});

		if (localStorage.getItem("cart")) {
			this.showCartDot = true;
		}

		// check if user is blocked either 429 or 403 and display message as appropriate, disable site
		var userBlocked = Number(this.ingrammicro.getCookie('user_blocked'))
		if(userBlocked == 429) {
			this.showAPIBlockPopup = 'Lockout in effect try again later, too many requests';
		} else if (userBlocked == 403) {
			this.showAPIBlockPopup = 'User is Blacklisted for Abuse';
		}

		/*
		jQuery(document).ready(function () {
			scrollCheck();
		});

		jQuery(window).scroll(function () {
			scrollCheck();
		});

		function scrollCheck() {
			if (jQuery(window).scrollTop() > 10) {
				jQuery('body').addClass('scrolled');
			} else {
				jQuery('body').removeClass('scrolled');
			}
		}
		*/

	}

	menuToggle() {
		jQuery('header .nav-menu').toggleClass('menu-open');
		jQuery('header .nav-menu .nav-link').toggleClass('show');
		jQuery('header .nav-controls').toggleClass('opened');
	}

	openLoginPopup() {
		if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0) { // if logged in
			this.router.navigateByUrl('/user/tradein');
		} else {
			jQuery('.loginpopup-overlay').fadeIn().css('display', 'flex');
		}
	}

	closeCookiePopup(){
		this.showCookiePopup = false;
	}

	scrollDown(){
		console.log('FIRED');

		var height = window.innerHeight / 2;
		window.scrollTo({ left: 0, top: height, behavior: 'smooth' });

/*
		jQuery([document.documentElement, document.body]).animate({
			scrollTop: jQuery(window).position().top += 100
		}, 1000);
		*/
	}

	isLoggedIn() {
		return this.headerService.isLoggedIn;
	}
}

