import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
	selector: 'app-bankdetails',
	templateUrl: './bankdetails.component.html',
	styleUrls: ['../cart.component.scss', './bankdetails.component.scss']
})
export class BankdetailsComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	bankName: String = null;
	bankNumber: String = null;
	bankSort: String = null;
	showValidationError: Boolean = false;
	loading: boolean = false;
  promoValue: number = 0;

	remainingAmount: number = null;
	upfrontCost: number = null;

	constructor(private router: Router, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));

			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
			}

		}

    if(localStorage.getItem('promo')) {
      this.promoValue = parseInt(localStorage.getItem('promo'));
    }

		if (localStorage.getItem('new-device-payment')) {
			var newDeviceCosts = JSON.parse(localStorage.getItem('new-device-payment'));
			console.log(newDeviceCosts);
			this.remainingAmount = newDeviceCosts['remaining-amount'];
			this.upfrontCost = newDeviceCosts['upfront-cost'];
		} 

	}

	checkoutContinue() {
		this.showValidationError = false;
		this.loading = true;
		this.ingrammicro.createRequest('POST', '/api/validator/bankdetails', 'Accountnumber='+this.bankNumber+'&Sortcode='+this.bankSort).subscribe(response => {
			if(response == true){
        var paymentType = JSON.parse(localStorage.getItem('payment'))['paymentType'];
				localStorage.setItem('payment', JSON.stringify({ "paymentType": paymentType, "AccountName": this.bankName, "AccountNumber": this.bankNumber, "AccountSort": this.bankSort }));
				this.router.navigateByUrl('/cart/details');
			} else {
				this.showValidationError = true;
			}
			this.loading = false;
		});
		
	}

}
