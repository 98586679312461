import { Component, OnInit } from '@angular/core';
import { FooterService } from '../../global/footer/footer.service';
import { HeaderService } from 'src/app/global/header/header.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  customerName: string = "";
  orderReference: string = "";
  deviceName: string = "";
  quotePrice: number = 0;
  paymentMethod: string = "";
  payment_id;
  imei: String = null;

  promo: number = 0;



  constructor(private headerService: HeaderService, private footerService: FooterService) { }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    if (localStorage.getItem("staff-user")) {
      var user = JSON.parse(localStorage.getItem("staff-user"));
      this.customerName = user['FirstName']+' '+user['LastName'];
    }

    var paymentMethodNumber = JSON.parse(localStorage.getItem("payment"));
    var userPaymentMethod = JSON.parse(paymentMethodNumber['paymentType']);

		if (userPaymentMethod == '2') {
			this.paymentMethod = 'Bank Transfer'
		}
		else if (userPaymentMethod == '13') {
			this.paymentMethod = 'PayPal'
		}
		else if (userPaymentMethod == '9') {
			this.paymentMethod = 'Bill Credit'
		}

    if (localStorage.getItem("reference")) {
      this.orderReference = JSON.parse(localStorage.getItem("reference"));
    }

    if (localStorage.getItem("cart")) {
      var cart = JSON.parse(localStorage.getItem("cart"));
      this.deviceName = cart[0]['phoneName'];
      this.quotePrice = cart[0]['price'];
      this.imei = cart[0]['imei'];
    }

    if (localStorage.getItem("promo")) {
      this.promo = JSON.parse(localStorage.getItem("promo")).value;
    }

    setTimeout(()=> {
      window.print();
    },1000);
  }

}
