import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HomepageLoginService } from 'src/app/login/homepage-login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pwrequest',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  constructor(private headerService: HeaderService, private ingrammicro: IngrammicroService, private loginService: HomepageLoginService, private formBuilder: FormBuilder) {
    
  }
  
  showForm: Boolean = true;
  showSuccess: Boolean = false;
  showFailure: Boolean = false;
  showMissingEmail: Boolean = false;
  email: String = null;
  aFormGroup: FormGroup;
  @Output() onClose = new EventEmitter<any>();

  ngOnInit() {
  }


  public closePasswordReset(){
    this.onClose.emit(false);
  }

  requestPasswordReset(){
    this.showMissingEmail = false;
    
    if(this.email != null){
      this.ingrammicro.createRequest('POST', '/api/Registration/RequestPasswordReset?Username='+this.email).subscribe(response => {
        if(response == true){
          this.showForm = false;
          this.showSuccess = true;
        } else {
          this.showForm = false;
          this.showFailure = true;
        }
      });
    } else {
      this.showMissingEmail = true;
    }
  }
}
