import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HeaderService } from '../global/header/header.service';
import { FooterService } from '../global/footer/footer.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';

import * as jQuery from 'jquery';
import * as slider from '../../assets/js/fusion-slider.js';
import { IngrammicroService } from '../ingrammicro.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
declare var Slider: any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './recycle.component.html',
	styleUrls: ['./recycle.component.scss']
})
export class RecycleComponent implements OnInit, OnDestroy {

	searchResults: Array<any> = [];
	searchTerm: String = "";
	searching: boolean = false;
	searchError: boolean = false;
	popularModels: Array<any> = [];
	homeSlider: any = null;
	showUnknownImeiPopup: Boolean = false;
	isUpgrade: boolean = false;
  
	aFormGroup: FormGroup;
	subscription: Subscription;
  
	constructor(private headerService: HeaderService, private http: HttpClient, private sanitization: DomSanitizer, private ingrammicro: IngrammicroService, private titleService: Title, private meta: Meta, private router: Router, private formBuilder: FormBuilder) {

	 }

	ngOnInit() {

		this.titleService.setTitle('Sell my phone | Three');
		//this.meta.addTag({name: 'keywords', content: 'Angular Project, Create Angular Project'});
		//this.meta.addTag({name: 'author', content: 'rsgitech'});
		this.meta.addTag({name: 'robots', content: 'index, follow'});


		this.headerService.setShowHeader(true);

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 4);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if(localStorage.getItem('isUpgrade') !== null) {
			this.isUpgrade = true;
			localStorage.setItem('cart', "");
			localStorage.setItem('payment', "");
			localStorage.setItem('user', "");
			localStorage.setItem('postage', "");
			localStorage.setItem('newAddress', "");
			localStorage.setItem('reference', "");
			localStorage.removeItem('new-device-payment');
			localStorage.removeItem('promo');
		}

		this.ingrammicro.createRequest('GET', '/api/Model/GetPopularModels').subscribe(response => {
			if (response) {
				for (var i = 0; i < Object.keys(response).length; i++) {
					this.popularModels.push({ 'model_id': response[i]['ModelID'], name: response[i]['Model'], "cashvalue": response[i]['CashValue'], "manufacturer": response[i]['Manufacturer'], "imageURL": this.sanitization.bypassSecurityTrustStyle('url(' + response[i]['ImageURL'] + ')') });
				}
				this.popularModels.reverse();

				jQuery(document).ready(function () {
					this.homeSlider = new Slider(jQuery);
					this.homeSlider.init('.phone-slider-container', '.phone-slide-outer', null, 9000, false, '.phone-slider-controls', 3, false);
				});
			}
		});
	}

	ngOnDestroy() {
		this.homeSlider = null;
	}

	bannerSearch() {
		if (!this.searchTerm.match(/[a-z]/i) && this.searchTerm.length > 3) {
			if (this.searchTerm.length == 15) {
				this.searchResults = [];
				this.searchResults = [{ "name": "IMEI Entered: " + this.searchTerm, "url": "/recycle" }];
			} else { //All numbers but not 15 characters
				this.searchResults = [{ "name": "IMEI Numbers must be 15 digits long", "url": "/recycle" }];
			}
		} else if (this.searchTerm.length < 4) { // Not enough letters, not worth searching for
			this.searchResults = [];
		} else if (this.searchTerm.length >= 10 && !this.searchTerm.includes(' ')) { //search by serial
			this.searchResults = [];
			this.searchResults = [{ "name": "Query Entered: " + this.searchTerm, "url": "/recycle" }];
			
		}  else { // Non-IMEI search term
			this.searchResults = [];

			if (this.searchTerm.length >= 12) {
				if(this.subscription) {
					this.subscription.unsubscribe();
				}

				this.searching = true;

				this.subscription = this.ingrammicro.createRequest('POST', '/api/Model/GetModelbyModelName', "modelname=" + this.searchTerm).subscribe(response => {
					this.searching = false;
					if (Object.keys(response).length > 0) {
						console.log(response);
						for (var i = 0; i < Object.keys(response).length; i++) {
							if(response[i]['ProductClassNo'] == 15 || response[i]['ProductClassNo'] == 21){
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewtablet/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							} else if(response[i]['ProductClassNo'] == 1){
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewphone/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							} else if(response[i]['ProductClassNo'] == 24 || response[i]['ProductClassNo'] == 29){
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewwearable/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							}
						}
						//this.searchValueSort();
					}
				});

				this.subscription = this.ingrammicro.createRequest('POST', '/api/IMEI/LookupModelFromIMEI?IMEI=' + this.searchTerm, 'IMEI=' + this.searchTerm).subscribe(response => {
					this.searching = false;
					if (response) {
						if(response['ProductClassNo'] == 15 || response['ProductClassNo'] == 21){
							this.searchResults.push({ "name": "Serial found: " +response['FullName'], "url": "/viewtablet/" + response['ModelID'], "value": response['CashValue'].replace(/\D/g, ''), "price": response['CashValue'] });
						} else if(response['ProductClassNo'] == 1){
							this.searchResults.push({ "name": "Serial found: " +response['FullName'], "url": "/viewphone/" + response['ModelID'], "value": response['CashValue'].replace(/\D/g, ''), "price": response['CashValue'] });
						} else if(response['ProductClassNo'] == 24 || response['ProductClassNo'] == 29){
							this.searchResults.push({ "name": "Serial found: " +response['FullName'], "url": "/viewwearable/" + response['ModelID'], "value": response['CashValue'].replace(/\D/g, ''), "price": response['CashValue'] });
						}
						//this.searchResults.push({ "name": "Serial found: " + response['FullName'], "url": "/viewphone/" + response['ModelID'] + "/" + this.searchTerm , "price": response['CashValue']});
					} else {
						this.showUnknownImeiPopup = true;
					}
				});
			} else {
				if(this.subscription) {
					this.subscription.unsubscribe();
				}
				this.searching = true;
				this.subscription = this.ingrammicro.createRequest('POST', '/api/Model/GetModelbyModelName', "modelname=" + this.searchTerm).subscribe(response => {
					this.searching = false;
					if (Object.keys(response).length > 0) {
						console.log(response);
						for (var i = 0; i < Object.keys(response).length; i++) {
							if(response[i]['ProductClassNo'] == 15 || response[i]['ProductClassNo'] == 21){
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewtablet/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							} else if(response[i]['ProductClassNo'] == 1){
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewphone/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							} else if(response[i]['ProductClassNo'] == 24 || response[i]['ProductClassNo'] == 29){
								this.searchResults.push({ "name": response[i]['FullName'], "url": "/viewwearable/" + response[i]['ModelID'], "value": response[i]['CashValue'].replace(/\D/g, ''), "price": response[i]['CashValue'] });
							}
						}
						//this.searchValueSort();
					}
				});
			}
		}
		if(this.searchTerm.length >= 4) {
			this.searchError = false;
		}
	}



	validateIMEI(){
		this.ingrammicro.createRequest('POST', '/api/IMEI/LookupModelFromIMEI?IMEI=' + this.searchTerm).subscribe(response => {
			if (response != null) {
				this.searchResults = [{ "name": response['FullName'], "url": "/viewphone/" + response['ModelID'] + "/" + this.searchTerm , "price": response['CashValue']}];
				if (response['CashValue'] == null) {
					this.searchResults = [{ "name": "Failed to get Model for IMEI", "url": "/recycle" }];
				} else {
					if(response['ProductClassNo'] == 15 || response['ProductClassNo'] == 21){
						this.router.navigateByUrl("/viewtablet/" + response['FullNameURLFriendly'] + '/' +  response['ModelID'] + "/" + this.searchTerm)
					  } else if (response['ProductClassNo'] == 1) {
						this.router.navigateByUrl("/viewphone/" + response['FullNameURLFriendly'] + '/' + response['ModelID'] + "/" + this.searchTerm)
					  } else if (response['ProductClassNo'] == 24 || response['ProductClassNo'] == 29) {
						this.router.navigateByUrl("/viewwearable/" + response['FullNameURLFriendly'] + '/' + response['ModelID'] + "/" + this.searchTerm)
					  }
				}
			} else {
				this.searchResults = [{ "name": "Failed to get Model for IMEI", "url": "/recycle" }];
			}
		}, error => {
			//api only sending default error response rather than 'Failed to get Model for IMEI: #############', so catch all errors for now as failed to get model
			// error['error']['Message']
			this.searchResults = [{ "name": "Failed to get Model for IMEI", "url": "/recycle" }];
		});
	}
	

	searchCheck (){
		if (this.searchTerm.length < 4) {
			this.searchError = true;
		}
		else {
			this.searchError = false;
		}
	}

	closeImeiPopup(){
		this.showUnknownImeiPopup = false;
	}

	/*
	searchValueSort() {
		this.searchResults.sort((a, b) => {
			if (parseFloat(a.value.replace("£", "")) < parseFloat(b.value.replace("£", ""))) return -1;
			else if (parseFloat(a.value.replace("£", "")) > parseFloat(b.value.replace("£", ""))) return 1;
			else return 0;
		});
		this.searchResults.reverse();
	}
	*/
}

