import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterService } from 'src/app/global/footer/footer.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { Router } from '@angular/router';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  username: String = null;
  emailAddress: String = null;
  firstName: String = null;
  lastName: String = null;
  password: String = 'ThreePW';
  error: Boolean = false;
  errorMsg: Boolean = false;
  submissionError: Boolean = false;
  apiError: String = null;
  stores: Array<String> = [];
  newStoreID: String = null;
  newRole: String = null;
  loggedInUserRole: String = null;
  loggedInStoreID: String = null;
  loggedInEmail: String = null;
  loggedInUserID: String = null;
  storeSearchString: String = null;
  storesReturned: Array<any> = [];
  isSearching: boolean = false;
  selectedStoreID: String = null;
  storeSelected: any = null;

  constructor(private headerService: HeaderService, private footerService: FooterService, private ingrammicro: IngrammicroService, private router: Router) { }

  ngOnInit() {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);

    if (localStorage.getItem("staff-user")) {
      var user = JSON.parse(localStorage.getItem('staff-user'));
      this.loggedInStoreID = user['StoreSenderNo'];
      this.loggedInUserRole = 'store-user';
      this.loggedInEmail = user['Email'];
      this.loggedInUserID = user['UserID'];

      if(user['RoleID'] == '8921af30-fa81-4ffb-8166-94e438567590'){
        this.loggedInUserRole = 'admin';
      } else if (user['RoleID'] == 'cb1350b1-f2e1-4abe-bc1a-8e6e0f0fc983') {
        this.loggedInUserRole = 'store-admin';
      }

      if(this.loggedInUserRole != 'admin' && this.loggedInUserRole != 'store-admin') {
        this.router.navigateByUrl('/dashboard');
      }
    }

    if (this.loggedInUserRole == 'admin') {
      this.ingrammicro.createRequest('GET', '/api/RetailUserManagement/GetStores?email='+this.loggedInEmail+'&UserID='+this.loggedInUserID).subscribe(response => {
        console.log(response);
         if(response){
           this.stores = response;
         } else {
           console.log('No stores');
         }
      });
    }

    if (this.loggedInUserRole != 'admin') {
      this.newRole = '8e71a38c-b749-4617-8da7-032b0560d3e7'; //regular user
      this.newStoreID = this.loggedInStoreID;
    }
  }

  createUser(){

     if(!this.username){
      this.error = true;
      jQuery("#userName").addClass('instore-portal-field-error')
    } else {
      jQuery("#userName").removeClass('instore-portal-field-error')
    }
     if(!this.emailAddress){
      this.error = true;
      jQuery("#email").addClass('instore-portal-field-error')
    } else {
      jQuery("#email").removeClass('instore-portal-field-error')
    }
    if(!this.firstName){
      this.error = true;
      jQuery("#firstName").addClass('instore-portal-field-error')
    } else {
      jQuery("#firstName").removeClass('instore-portal-field-error')
    }
    if(!this.lastName){
      this.error = true;
      jQuery("#lastName").addClass('instore-portal-field-error')
      } else {
        jQuery("#lastName").removeClass('instore-portal-field-error')
      }
    if(!this.storeSelected){
      this.error = true;
      jQuery("#store").addClass('instore-portal-field-error')
      } else {
        jQuery("#store").removeClass('instore-portal-field-error')
      }
    if(!this.newRole){
      this.error = true;
      jQuery("#role").addClass('instore-portal-field-error')
      } else {
        jQuery("#role").removeClass('instore-portal-field-error')
      }

    if (this.username && this.firstName && this.lastName && this.emailAddress && this.newStoreID && this.newRole) {
      this.error = false;
    }

    if(this.error == false){
      this.submissionError = false;
      jQuery('#instore-portal-form-submit').html('Loading');
      this.ingrammicro.createRequest('POST', '/api/RetailUserManagement/CreateStoreUser', "firstName="+this.firstName+'&surname='+this.lastName+'&email='+this.emailAddress+'&Username='+this.emailAddress+'&password='+this.password+'&StoreID='+this.newStoreID+'&role='+this.newRole).subscribe(response => {
        if(response['IsValid'] == false){
          this.submissionError = true;
          this.apiError = response['ValidationMessage'];
          jQuery('#instore-portal-form-submit').html('Confirm');
        } else {
          this.submissionError = false;
          this.router.navigateByUrl('/admin/user/view');
        }
      });
    }
  }

  selectChangeHandler (event: any) {
    this.newStoreID = event.target.value;
  }

  selectChangeHandlerRole (event: any) {
    this.newRole = event.target.value;
  }

  searchForStores() {
    this.isSearching = true;
    this.storesReturned = [];
    this.ingrammicro.createRequest('GET', '/api/RetailUserManagement/GetStoresSearch?email='+this.loggedInEmail+'&UserID='+this.loggedInUserID+'&SearchText='+this.storeSearchString).subscribe(response => {
        this.storesReturned = response;
        if(this.storesReturned.length < 1) {
            this.storesReturned[0] = {SenderName: 'No Stores Found'};
        }
    }, response => {
      console.error(response);
    });
    this.isSearching = false;
  }

  selectStore(storeSelected){
    this.storeSelected = storeSelected;
    this.storeSearchString = storeSelected.SenderName;
    this.newStoreID = storeSelected.SenderNo;
    this.storesReturned = [];
  }

  onSearchChange(searchValue: string) {
    this.searchForStores();
  }
}
